import React from "react"
import "./enterAmount.scss"
import { useTranslation } from "react-i18next"

const EnterAmount = ({
  minDeposit,
  maxDeposit,
  error,
  value,
  handleInput,
  isDisabled,
}) => {
  const {t} = useTranslation()
  return (
    <div className="enter-amount-section">
      <p className="enter-amount-section-text">{t("Your amount")}</p>
      <div className="enter-amount-block">
        <div className="enter-amount-block-text">
          <p>{t("Enter the amount:")}</p>
        </div>
        <div className="enter-amount-input">
          <input
            type="text"
            inputMode="numeric"
            minLength={minDeposit}
            maxLength={maxDeposit}
            value={value}
            onInput={handleInput}
            disabled={isDisabled}
          />
        </div>
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  )
}

export default EnterAmount
