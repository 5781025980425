import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import click from "../../shared/assets/audio/click.mp3"
import { toggleClickSound } from "../../app/actions/musicAction"

const BackgroundMusic = () => {
  const clickPlaying = useSelector((state) => state.sound.clickSoundIsPlaying)
  const isPlaying = useSelector((state) => state.sound.isPlaying)

  const audio = React.useRef(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const audioElement = new Audio(click)
    audio.current = audioElement
    audioElement.volume = 0.05
    audioElement.loop = false
    if (clickPlaying && isPlaying) {
      audioElement.currentTime = 0
      audioElement.play()
      dispatch(toggleClickSound())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickPlaying])

  return null
}

export default BackgroundMusic
