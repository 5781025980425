import React, { useState, useEffect, useRef } from "react"
import "./game-content.scss"
import {
  MainPageAnimation,
  MainPageCenter,
  MainPagePlayerItem,
  MainPagePlayerListBtn,
  MainPageToast,
} from "../../../shared/ui"
import MainGameCoefficient from "../../../shared/ui/main/main-game-coefficient/ui"
import {
  calculateExponentialGrowthRate,
  calculateExponentialElapsedTime,
} from "../../../shared/lib/hooks/use-calculate-coefficient/use-calculate-coefficient"
import useGamingSession from "../../../shared/lib/hooks/gaming-session/gaming-session.hook"
import usePlayer from "../../../shared/lib/hooks/player/player.hook"
import { useTranslation } from "react-i18next"

const GameHeader = ({ betButtonError }) => {
  const {
    currentMultiplier,
    allDataDurationCurrentGamingSession,
    previousGameSessions,
    isGamingSessionInitialized,
    isGamingSessionStarted,
    isGamingSessionFinished,
    myDataDurationCurrentGamingSession,
  } = useGamingSession()
  const { t } = useTranslation()
  const { winnings, player, betError } = usePlayer()
  const foundedMyDataDurationCurrentGamingSession =
    allDataDurationCurrentGamingSession.find((item) => item.id === player.id)

  const prevPlayerWinningsRef = useRef(null)
  const [activePlayerList, setActivePlayerList] = useState(true)
  const [nextCoefficients, setNextCoefficients] = useState(0)

  const [playerWinnings, setPlayerWinnings] = useState(null)
  const [superGame, setSuperGame] = useState(false)

  useEffect(() => {
    if (!superGame && currentMultiplier >= 20) {
      setSuperGame(true)
    } else if (superGame && currentMultiplier < 1) {
      const timer = setTimeout(() => {
        setSuperGame(false)
      }, 2000)

      return () => clearTimeout(timer)
    }
  }, [superGame, currentMultiplier])

  useEffect(() => {
    const interval = setInterval(() => {
      setNextCoefficients(
        calculateExponentialGrowthRate(
          calculateExponentialElapsedTime(currentMultiplier || 1, 1, 100, 53) +
            1,
          1,
          100,
          53
        )
      )
    }, 0)

    return () => clearInterval(interval)
  }, [currentMultiplier])

  useEffect(() => {
    if (winnings && !prevPlayerWinningsRef.current) {
      setPlayerWinnings(winnings)

      const timeoutId = setTimeout(() => {
        setPlayerWinnings(null)
        prevPlayerWinningsRef.current = null
      }, 3000)

      return () => clearTimeout(timeoutId)
    } else {
      setPlayerWinnings(null)
      prevPlayerWinningsRef.current = null
    }
  }, [winnings])

  useEffect(() => {
    prevPlayerWinningsRef.current = playerWinnings
  }, [playerWinnings])

  const {
    loading,
    bet,
  } = usePlayer()

  const [pingIssue, setPingIssue] = useState(false);

  useEffect(() => {
    if (bet && loading && isGamingSessionStarted) {
      setPingIssue(true);
    }
  
    const timeoutId = setTimeout(() => {
      setPingIssue(false);
    }, 3000);
    return () => clearTimeout(timeoutId);

  }, [bet, loading, isGamingSessionStarted, isGamingSessionFinished]);




  return (
    <div className="game-content">
      <div className="game-screen-players">
        <MainPagePlayerListBtn
          active={activePlayerList}
          onClick={() => setActivePlayerList(!activePlayerList)}
        />

        <ul className="game-screen__players-list" id="js-stake-list">
          {myDataDurationCurrentGamingSession ? (
            <MainPagePlayerItem
              id={myDataDurationCurrentGamingSession.id}
              name={myDataDurationCurrentGamingSession.name}
              sum={myDataDurationCurrentGamingSession.bet}
              active={activePlayerList}
              winnings={
                myDataDurationCurrentGamingSession.multiplier
                  ? Math.floor(
                      myDataDurationCurrentGamingSession.multiplier *
                        myDataDurationCurrentGamingSession.bet *
                        100
                    ) / 100
                  : null
              }
              multiplier={myDataDurationCurrentGamingSession.multiplier}
            />
          ) : (
            foundedMyDataDurationCurrentGamingSession && (
              <MainPagePlayerItem
                name={foundedMyDataDurationCurrentGamingSession.name}
                sum={foundedMyDataDurationCurrentGamingSession.bet}
                active={activePlayerList}
                id={foundedMyDataDurationCurrentGamingSession.id}
                winnings={
                  foundedMyDataDurationCurrentGamingSession.multiplier
                    ? Math.floor(
                        foundedMyDataDurationCurrentGamingSession.multiplier *
                          foundedMyDataDurationCurrentGamingSession.bet *
                          100
                      ) / 100
                    : null
                }
                multiplier={
                  foundedMyDataDurationCurrentGamingSession.multiplier
                }
              />
            )
          )}

          {allDataDurationCurrentGamingSession ? (
            allDataDurationCurrentGamingSession
              .filter((item) => item.id !== player.id)
              .map((player) => (
                <MainPagePlayerItem
                  key={player.id}
                  name={player.name}
                  sum={player.bet}
                  active={activePlayerList}
                  id={player.id}
                  winnings={player.winnings}
                  multiplier={player.multiplier}
                />
              ))
              .slice(0, 15)
          ) : (
            <></>
          )}
        </ul>
      </div>
      <div
        className={"game-screen-aviator " + (superGame ? " super-game" : "")}
      >
        {isGamingSessionStarted || isGamingSessionFinished ? (
          <MainGameCoefficient
            start={
              currentMultiplier ||
              (isGamingSessionStarted ? 1 : previousGameSessions[0].finalResult)
            }
            end={
              isGamingSessionFinished
                ? previousGameSessions[0].finalResult
                : nextCoefficients
            }
          />
        ) : (
          <></>
        )}
        {isGamingSessionInitialized ? (
          <MainPageCenter active={activePlayerList} />
        ) : (
          <></>
        )}
        {isGamingSessionStarted ||
        isGamingSessionFinished ||
        isGamingSessionInitialized ? (
          <MainPageAnimation />
        ) : (
          <></>
        )}

        <div className="toast-container">
          {betError.map((item, index) => {
            return (
              <MainPageToast
                itemKey={index}
                toastType={"toast_error"}
                header={t("Error") + "!"}
                text={t(item.message)}
              />
            )
          })}

          {betButtonError.map((item, index) => (
            <MainPageToast
              key={index} // Используйте key вместо itemKey
              toastType={"toast_error"}
              header={t("Error") + "!"}
              text={t(item.message)}
            />
          ))}

          {pingIssue && (
            <MainPageToast
              key={1} // Используйте key вместо itemKey
              toastType={"toast_error"}
              header={t("Error") + "!"}
              text={t("Bet not sent. Bad connection.")}
            />
          )}
          
          {playerWinnings && (
            <MainPageToast
              toastType={"toast_success"}
              header={t("Congratulations") + "!"}
              text={t(`You win`) + ` ${playerWinnings.toFixed(2)} INR!`}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default GameHeader
