import React, { useState, useEffect } from "react"
import "./main-game-header-actions.scss"
import { Link } from "react-router-dom"
import infoImg from "./../../../shared/assets/main/info.webp"
import soundOffImg from "./../../../shared/assets/main/sound-off.png"
import soundOnImg from "./../../../shared/assets/main/sound-on.png"
import usePlayer from "../../../shared/lib/hooks/player/player.hook"
import useGamingSession from "../../../shared/lib/hooks/gaming-session/gaming-session.hook"
import { useDispatch, useSelector } from "react-redux"
import { toggleMusic } from "../../../app/actions/musicAction"
import { useTranslation } from "react-i18next"
import { Icon } from "../../../shared/ui"
import { DEFAULT_AVATAR_CONST } from "../../../shared/utils/consts/player.const"

const MainGameCheckbox = () => {
  const { t } = useTranslation()
  const { currentGamingSession } = useGamingSession()
  const { photo } = usePlayer()
  const [popupMenu, setPopupMenu] = useState(false)
  const [soundIcon, setSoundIcon] = useState(false)
  const [hashCopied, setHashCopied] = useState(false)

  const copyToClipboard = (text) => {
    const textArea = document.createElement("textarea")
    textArea.value = text
    document.body.appendChild(textArea)
    textArea.select()

    try {
      document.execCommand("copy")
      setHashCopied(true)
    } catch (err) {
      console.error("Failed to copy text: ", err)
    }

    document.body.removeChild(textArea)
  }

  useEffect(() => {
    if (hashCopied === true) {
      const timer = setTimeout(() => {
        setHashCopied(false)
      }, 3000)

      return () => clearTimeout(timer)
    }
  }, [hashCopied])

  const formatHash = (hash) => {
    if (!hash) return "..."
    return `${hash.slice(0, 8)}...${hash.slice(-10)}`
  }

  const dispatch = useDispatch()

  const handleToggleMusic = () => {
    dispatch(toggleMusic())
    setSoundIcon(!soundIcon)
  }
  const isPlaying = useSelector((state) => state.sound.isPlaying)
  const { player } = usePlayer()

  return (
    <div className={"header-actions" + (popupMenu ? " active" : "")}>
      {player && player?.id > 0 ? (
        <Link
          to={"/profile"}
          className="header-actions-settings"
          rel="noopener noreferrer"
        >
          {photo === DEFAULT_AVATAR_CONST ? (
            <Icon type="profile" width={28} height={28} alt="userAvatar-icon" />
          ) : (
            <img
              className="avatar-img"
              src={photo}
              alt="User Avatar"
              crossOrigin="anonymous"
            />
          )}
        </Link>
      ) : (
        <Link
          to={"/info-telegram"}
          className="header-actions-settings"
          rel="noopener noreferrer"
        >
          {photo === DEFAULT_AVATAR_CONST ? (
            <Icon type="profile" width={28} height={28} alt="userAvatar-icon" />
          ) : (
            <img
              className="avatar-img"
              src={photo}
              alt="User Avatar"
              crossOrigin="anonymous"
            />
          )}
        </Link>
      )}
      <button
        className="header-actions-volume"
        onClick={handleToggleMusic}
        id="js-disable-sound"
      >
        {!isPlaying ? (
          <Icon
            type="speakerOff"
            width={28}
            height={28}
            alt="speakerOff-icon"
          />
        ) : (
          <Icon type="speakerOn" width={28} height={28} alt="speakerOn-icon" />
        )}
      </button>

      <button
        type="button"
        className="header-actions-info"
        onClick={() => setPopupMenu(!popupMenu)}
      >
        <Icon type="info" width={28} height={28} alt="info-icon" />
      </button>

      <div className={"header-actions-gameinfo" + (popupMenu ? " active" : "")}>
        <div className="header-actions-gameinfo-item">
          <h3>{t("Game ID")}</h3>
          <p className="now_ID">{currentGamingSession?.id || "..."}</p>
        </div>
        <div className="header-actions-gameinfo-item">
          <h3>{t("Hash")}</h3>
          <div
            className="header-actions-gameinfo-clipboard"
            onClick={() => copyToClipboard(currentGamingSession?.hash)}
          >
            <span className="header-actions-gameinfo-clipboard-text now-HASH">
              {hashCopied
                ? t("Copied")
                : formatHash(currentGamingSession?.hash) || "..."}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainGameCheckbox
