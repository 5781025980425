import React from "react"
import { Link } from "react-router-dom"
import { Icon } from "../../"
import "./refillBalanceButton.scss"

const RefillBalanceButton = ({
  iconType,
  text,
  link,
  onClick,
  alt_text,
  backgroundImage,
}) => {
  const buttonStyles = {
    backgroundImage: `url(${backgroundImage})`,
  }
  return (
    <Link to={link} rel="noopener noreferrer">
      <button
        className="refill-balance-button"
        onClick={onClick}
        style={backgroundImage ? buttonStyles : {}}
      >
        <Icon type={iconType} width={58} height={58} alt={alt_text} />
        <span>{text}</span>
      </button>
    </Link>
  )
}

export default RefillBalanceButton
