import { createSlice } from "@reduxjs/toolkit"
import { gamingSessionStatusEnum } from "../../../shared/utils"

const initialState = {
  gamingSessionStatus: null,
  currentMultiplier: null,
  currentGamingSession: null,
  previousGameSessions: [],
  myDataDurationCurrentGamingSession: null,
  allDataDurationCurrentGamingSession: [],
  gameConsts: null,
  currentFrontendMultiplier: null,
}

const gamingSessionSlice = createSlice({
  name: "gamingSession",
  initialState,
  reducers: {
    setCurrentFrontendMultiplier(state, action) {
      state.currentFrontendMultiplier = action.payload.currentFrontendMultiplier
    },
    gamingSessionFinish(state, action) {
      state.gamingSessionStatus = gamingSessionStatusEnum.FINISHED

      state.previousGameSessions = [
        {
          id: action.payload.gamingSessionId,
          finalResult: action.payload.finalResult,
        },
        ...state.previousGameSessions,
      ].slice(0, 25)

      state.currentMultiplier = null
      state.currentGamingSession = null
    },
    gamingSessionInit(state, action) {
      state.currentFrontendMultiplier = null
      state.gamingSessionStatus = gamingSessionStatusEnum.INITIALIZED
      state.currentGamingSession = {
        id: action.payload.gamingSessionId,
        hash: action.payload.hash,
      }

      state.allDataDurationCurrentGamingSession = []
    },
    gamingSessionStart(state, action) {
      state.currentFrontendMultiplier = null
      state.gamingSessionStatus = gamingSessionStatusEnum.STARTED
    },
    currentMultiplier(state, action) {
      state.currentMultiplier = action.payload.multiplier
    },
    playerMakeBet(state, action) {
      state.allDataDurationCurrentGamingSession = [
        {
          id: action.payload.playerId,
          bet: action.payload.bet,
          photo: action.payload.photoUri,
          name: action.payload.playerName,
        },
        ...state.allDataDurationCurrentGamingSession,
      ]
    },
    playerMakeCashout(state, action) {
      state.allDataDurationCurrentGamingSession =
        state.allDataDurationCurrentGamingSession.map((item) => {
          if (item.id === action.payload.playerId) {
            return {
              ...item,
              winnings: action.payload.winnings,
              multiplier: action.payload.multiplier,
            }
          }
          return item
        })
    },
    playerCancelBet(state, action) {
      state.allDataDurationCurrentGamingSession =
        state.allDataDurationCurrentGamingSession.filter(
          (item) => item.id !== action.payload.playerId
        )
    },
    initGamingSessionData(state, action) {
      state.currentMultiplier = action.payload.currentMultiplier
      state.currentFrontendMultiplier = action.payload.currentMultiplier
      state.currentGamingSession = action.payload.currentGamingSession
      state.previousGameSessions = action.payload.previousGameSessions.slice(
        0,
        25
      )
      state.myDataDurationCurrentGamingSession =
        action.payload.myDataDurationCurrentGamingSession
      state.allDataDurationCurrentGamingSession =
        action.payload.allDataDurationCurrentGamingSession
      state.gameConsts = action.payload.gameConsts
      if (
        action.payload.currentGamingSession &&
        action.payload.currentMultiplier
      ) {
        state.gamingSessionStatus = gamingSessionStatusEnum.STARTED
      }

      if (
        !action.payload.currentGamingSession &&
        !action.payload.currentMultiplier
      ) {
        state.gamingSessionStatus = null
      }

      if (
        action.payload.currentGamingSession &&
        !action.payload.currentMultiplier
      ) {
        state.gamingSessionStatus = gamingSessionStatusEnum.INITIALIZED
      }
    },
    setDefaultMyDataDurationCurrentGamingSession(state) {
      state.myDataDurationCurrentGamingSession =
        initialState.myDataDurationCurrentGamingSession
    },
  },
})

export const {
  gamingSessionFinish,
  gamingSessionInit,
  gamingSessionStart,
  currentMultiplier,
  playerMakeBet,
  playerMakeCashout,
  playerCancelBet,
  initGamingSessionData,
  setCurrentFrontendMultiplier,
  setDefaultMyDataDurationCurrentGamingSession,
} = gamingSessionSlice.actions

export default gamingSessionSlice.reducer
