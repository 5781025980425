import React from "react"
import "./main-game-header-last-game-block.scss"

const LastGameBlock = ({ value, onClick, id }) => {
  return (
    <div className="last-block-wrapper">
      <div
        onClick={onClick}
        className={
          "last-game-block" +
          (value < 3 ? " green" : value < 15 ? " yellow" : " blue")
        }
      >
        {value.toFixed(2) + "X"}
      </div>
    </div>
  )
}

export default LastGameBlock
