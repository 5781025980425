import api from "../../../../api"
import { endpoints } from "../../../../endpoints"

const { get } = endpoints.player.players

const getMyPhotoStorePlayersService = () => {
  try {
    return api.get(get.getPhoto).then((response) => {
      return response
    })
  } catch (_) {}
}

export const StorePlayersService = {
  getMyPhotoStorePlayersService,
}
