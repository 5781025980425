import { useCallback } from "react"
import { handleConnect } from "../../../api/socket.io/handlers/connect.handler"
import { useDispatch } from "react-redux"

const useConnectHandler = () => {
  const dispatch = useDispatch()
  const handleConnectCallback = useCallback(
    () => handleConnect(dispatch),
    [dispatch]
  )

  return handleConnectCallback
}

export default useConnectHandler
