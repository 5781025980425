import React, { useState, useEffect } from "react"
import "./orderPaymentGrid.scss"
import useAPayGetPaymentSystemLimitationDepositWithdrawal from "../../../shared/lib/hooks/withdrawals/a-pay-get-payment-system-limitation-withdrawal.hook"
import useAPayCreateWithdrawal from "../../../shared/lib/hooks/withdrawals/a-pay-create-withdrawal.hook"
import { withdrawalsEnum } from "../../../shared/utils"
import {
  Icon,
  InputPayment,
  PayOrderButton,
  PaymentTable,
} from "../../../shared/ui"
import { useTranslation } from "react-i18next"

const OrderPaymentGrid = () => {
  const {t} = useTranslation()
  const [amount, setAmount] = useState("")
  const [errorAmount, setErrorAmount] = useState("")
  const [error, setError] = useState("")
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [formData, setFormData] = useState({
    accountName: "",
    accountNumber: "",
    bankCode: "BKID0",
  })

  const { data: dataLimitation } =
    useAPayGetPaymentSystemLimitationDepositWithdrawal(withdrawalsEnum.IMPS)
  const {
    createWithdrawal,
    error: errorCreateWithdrawal,
    loading: loadingCreateWithdrawal,
  } = useAPayCreateWithdrawal()

  useEffect(() => {
    const isAmountValid =
      amount &&
      Number(amount) >= dataLimitation.minWithdrawal &&
      Number(amount) <= dataLimitation.maxWithdrawal
    const words = formData.accountName.trim().split(" ")
    const isAccountNameValid =
      words.length <= 5 &&
      /^[A-Za-z\s]+$/.test(formData.accountName) &&
      !/\s$/.test(formData.accountName)
    const isAccountNumberValid = /^\d+$/.test(formData.accountNumber)
    const isBankCodeValid = /^BKID0\d{6}$/.test(formData.bankCode)
    setIsButtonDisabled(
      !(
        isAmountValid &&
        isAccountNameValid &&
        isAccountNumberValid &&
        isBankCodeValid
      )
    )
  }, [amount, formData, dataLimitation])

  const handleAmountInput = (e) => {
    const newValue = e.target.value
    if (/^\d*$/.test(newValue)) {
      setAmount(newValue)
      if (newValue && Number(newValue) < dataLimitation.minWithdrawal) {
        setErrorAmount(
          `The value must be at least ${dataLimitation.minWithdrawal}`
        )
      } else if (newValue && Number(newValue) > dataLimitation.maxWithdrawal) {
        setErrorAmount(
          `The value must not exceed ${dataLimitation.maxWithdrawal}`
        )
      } else {
        setErrorAmount("")
      }
    }
  }

  const handleAccountNameInput = (e) => {
    let newValue = e.target.value
    newValue = newValue.replace(/[^A-Za-z\s]/g, "")
    newValue = newValue.replace(/\s+/g, " ")
    const words = newValue.split(" ")

    if (words.length > 5) {
      setError("The maximum allowed is five words.")
    } else {
      setError("")
    }

    setFormData({ ...formData, accountName: newValue })
  }

  const handleAccountNumberInput = (e) => {
    const newValue = e.target.value
    if (/^\d*$/.test(newValue)) {
      setFormData({ ...formData, accountNumber: newValue })
    }
  }

  const handleBankCodeInput = (e) => {
    const newValue = e.target.value
    if (/^BKID0\d{0,6}$/.test(newValue)) {
      setFormData({ ...formData, bankCode: newValue })
    }
  }

  return (
    <div className="select-payment">
      <p className="select-title">{t("Payment to IMPS")}</p>
      <Icon
        type="imps"
        alt="impsIcon"
        width={160}
        height={72}
        className="imps-img"
      />
      <p className="comission-text">{t("The payment gateway commission is 4%")} </p>
      {errorCreateWithdrawal && (
        <p className="select-payment-error-back-message">
          {errorCreateWithdrawal}
        </p>
      )}
      <div className="select-payment-input-wrapper">
        <InputPayment
          text={t("Amount (300)")}
          onChange={handleAmountInput}
          minLength={dataLimitation.minWithdrawal}
          maxLength={dataLimitation.maxWithdrawal}
          value={amount}
          error={errorAmount}
          isDisabled={loadingCreateWithdrawal}
        />
        <InputPayment
          text={t("Account Name (Name Surname)")}
          type="text"
          name="accountName"
          value={formData.accountName}
          onChange={handleAccountNameInput}
          error={error}
          isDisabled={loadingCreateWithdrawal}
        />
        <InputPayment
          text={t("Account Number (123456789)")}
          type="text"
          name="accountNumber"
          value={formData.accountNumber}
          onChange={handleAccountNumberInput}
          isDisabled={loadingCreateWithdrawal}
        />
        <InputPayment
          text={t("Bank Code (BKID0123456)")}
          type="text"
          name="bankCode"
          value={formData.bankCode}
          onChange={handleBankCodeInput}
          isDisabled={loadingCreateWithdrawal}
        />
      </div>
      <PayOrderButton
        isDisabled={isButtonDisabled || loadingCreateWithdrawal}
        text={t("Order a payment")}
        onClick={() => {
          if (!isButtonDisabled) {
            createWithdrawal(
              Number(amount),
              withdrawalsEnum.IMPS,
              formData.accountName,
              formData.accountNumber,
              formData.bankCode
            )
          }
        }}
      />
      <PaymentTable />
    </div>
  )
}

export default OrderPaymentGrid
