import React, { useState, useEffect } from "react"
import {
  BetSection,
  DisconectPopup,
  GameContent,
  GameHeader,
  MusicElement,
  SoundElement,
  SoundBallElement,
  SoundClickElement,
} from "../../../widgets"
import "./mainPage.scss"
import useSocketEventListener from "../../../shared/lib/hooks/socket.io/socket-event-listener.hook"
import useInitialGamingSessionData from "../../../shared/lib/hooks/gaming-session/init-gaming-session-data.hook"
import usePlayer from "../../../shared/lib/hooks/player/player.hook"
import Lottie from "react-lottie"
import BallNormalAnimation from "./../../../shared/ui/main/main-game-animation/ui/animation/ball_01.json"
import { CommonService } from "../../../shared/api/axios/requests/general/common/common.service"

const pingLimit = process.env.REACT_APP_PING_LIMIT;

const MainPage = () => {
  useSocketEventListener()
  const { isConnected } = usePlayer()
  const { isLoadingInitialGamingSessionData } = useInitialGamingSessionData()
  const [betButtonError, setBetButtonError] = useState([])
  const [isFirstLoading, setIsFirstLoading] = useState(true)

  const { pingCommonService } = CommonService()
  const [isUnstableInternetConnection, setIsUnstableInternetConnection] =
    useState(true)

  useEffect(() => {
    const checkPing = async () => {
      const startTime = Date.now()

      try {
        await pingCommonService()
        const endTime = Date.now()
        const ping = endTime - startTime

        if (ping > parseInt(pingLimit, 10)) {
          setIsUnstableInternetConnection(true)
          console.error(`Ping exceeds ${pingLimit} ms:`, ping, "ms")
        } else {
          setIsUnstableInternetConnection(false)
          console.log("Ping is normal:", ping, "ms")
        }
      } catch (error) {
        setIsUnstableInternetConnection(true)
        console.error("Error during the request:", error)
      }
    }

    checkPing()

    const intervalId = setInterval(() => {
      checkPing()
    }, 10000)

    return () => clearInterval(intervalId)
  }, [pingCommonService])

  const Ball = {
    loop: true,
    autoplay: true,
    animationData: BallNormalAnimation,
  }


  useEffect(() => {

    if (isConnected && isFirstLoading) {
      setIsFirstLoading(false)
    }
  }, [isConnected])

  // if (isUnstableInternetConnection) {
  //   return 
  //   <div className="game-wrapper-background">
  //     <div className="game-wrapper">
  //       <DisconectPopup />
  //     </div>
  //   </div>
  // }

  if (isFirstLoading) {
    return (
      <div className="game-wrapper-background">
        <div className="game-wrapper">
          <div className="loading">
            <Lottie options={Ball} height={600} width={444} />
            <div className="loading-text">Loading...</div>

          </div>
          <div className="copyright">Cricket Jet</div>
        </div>
      </div>
    )
  }
  const isIPhone = /iPhone/i.test(navigator.userAgent);
  if (isIPhone) {
    document.body.classList.add('iphone');
  }
  const handleInputFocus = () => {
    if (isIPhone) {
      document.body.style.overflow = 'hidden';
      document.querySelector('.game-wrapper').style.height = 'calc(100vh - 300px)';
    }
  };

  const handleInputBlur = () => {
    if (isIPhone) {
      document.body.style.overflow = 'auto';
      document.querySelector('.game-wrapper').style.height = 'calc(100vh - 36px)';
    }
  };


  return (
    <div className="game-wrapper-background">
      <div className="game-wrapper">
        {isUnstableInternetConnection ? <DisconectPopup text={"Unstable internet connection."}/> : <></>}
        {!isConnected && !isFirstLoading ? <DisconectPopup text={"Lost connection to server"}/> : <></>}
        <GameHeader />
        <GameContent betButtonError={betButtonError} onInputFocus={handleInputFocus} onInputBlur={handleInputBlur} />
        <BetSection setBetButtonError={setBetButtonError} onInputFocus={handleInputFocus} onInputBlur={handleInputBlur} />
        <MusicElement />
        <SoundElement />
        <SoundBallElement />
        <SoundClickElement />
      </div>
    </div>
  )
}

export default MainPage
