import React, { useState, useEffect } from "react"
import "./burgerMenu.scss"
import { MenuItem, MenuIcon, ChangeLanguage } from "../../../shared/ui"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"

const BurgerMenu = () => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const closeMenu = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    closeMenu()
  }, [location])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        !event.target.closest(".side-menu") &&
        !event.target.closest(".menu-icon")
      ) {
        closeMenu()
      }
    }

    document.addEventListener("click", handleClickOutside)

    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [isOpen])

  return (
    <div>
      <MenuIcon isOpen={isOpen} toggleMenu={toggleMenu} />
      <nav className={`side-menu ${isOpen ? "open" : ""}`}>
        <ul>
          <MenuItem
            iconType="logout"
            text={t("Back to Game")}
            link="/GAME_ENGINE"
            alt_text="back-icon"
          />
          <MenuItem
            iconType="coins"
            text={t("Refill Balance")}
            link="/refill"
            alt_text="refill-icon"
          />
          <MenuItem
            iconType="withdrawal"
            text={t("Order a payment")}
            link="/payment"
            alt_text="withdrawal-icon"
            color="white"
          />
          <MenuItem
            iconType="transaction"
            text={t("Transaction History")}
            link="/transaction"
            alt_text="transaction-icon"
          />
          <MenuItem
            iconType="support"
            text={t("Technical Support")}
            link="https://t.me/cricketjet_support"
            alt_text="support-icon"
            target="_blank"
          />
          <MenuItem
            iconType="settings"
            text={t("Your Profile")}
            link="/profile"
            alt_text="profile-icon"
          />

          {/* <ChangeLanguage /> */}
        </ul>
      </nav>
    </div>
  )
}

export default BurgerMenu
