const getLocalMode = () => {
  const mode = JSON.parse(localStorage.getItem("mode"))
  return mode ? mode : "telegram"
}

const updateLocalMode = (mode) => {
  localStorage.setItem("mode", JSON.stringify(mode))
}

const setLocalMode = (mode) => {
  localStorage.setItem("mode", JSON.stringify(mode))
}

const removeLocalMode = () => {
  localStorage.removeItem("mode")
}

export const ModeService = {
  getLocalMode,
  updateLocalMode,
  setLocalMode,
  removeLocalMode,
}
