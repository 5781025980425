import React, { useEffect, useState } from "react"
import "./main-game-toast.scss"
import { useDispatch } from "react-redux"
import { setCurrentFrontendMultiplier } from "../../../../../features/gaming-session/model/slice"
import useGamingSession from "../../../../lib/hooks/gaming-session/gaming-session.hook"

const MainGameCoefficient = ({ start, end }) => {
  const dispatch = useDispatch()
  const [value, setValue] = useState(start)
  const [endCoefProgress, setEndCoefProgress] = useState(false)

  const { isGamingSessionInitialized } = useGamingSession()

  useEffect(() => {
    if (value === end) {
      setEndCoefProgress(true)
    }

    if (isGamingSessionInitialized === true) {
      setEndCoefProgress(false)
    }

    if (end >= 1 || !endCoefProgress) {
      const startTime = performance.now()
      const duration = 1150 // Duration in milliseconds

      const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime
        if (elapsedTime < duration) {
          const progress = elapsedTime / duration
          setValue(start + progress * (end - start))
          requestAnimationFrame(animate)
        } else {
          setValue(end)
        }
      }
      requestAnimationFrame(animate)
      return () => {
        cancelAnimationFrame(animate)
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end, isGamingSessionInitialized])

  useEffect(() => {
    dispatch(
      setCurrentFrontendMultiplier({
        currentFrontendMultiplier: Math.floor(value * 100) / 100,
      })
    )
  }, [value, dispatch])

  return (
    <div className="game__kef" id="kef">
      {value.toFixed(2)}X
    </div>
  )
}

export default MainGameCoefficient
