import React from "react"
import "./transactionTable.scss"
import useGetMyTransactionHistory from "../../lib/hooks/transactions/get-my-transaction-history.hook"
import { formatDateToDDMMHHMMStringWithIn } from "../../utils/functions/format-date-to-dd-mm-hh-mm-string-with-in"
import { formatDateToLocalDate } from "../../utils/functions/format-date-to-local-date"
import { useTranslation } from "react-i18next"
import formatDate from "../../services/i18n/formatDatei18"


const TransactionTable = () => {
  const { t, i18n } = useTranslation();
  const { data, loading } = useGetMyTransactionHistory()
  if (loading) {
    return <></>
  }
  const getAmountClass = (action) => {
    switch (action) {
      case "add_funds":
        return "win-table"
      case "deduct_funds":
        return "lose-table"
      case "no_action":
        return "neutral-table"
      default:
        return ""
    }
  }

  const formatAmount = (action, amount) => {
    switch (action) {
      case "add_funds":
        return `+${amount}`
      case "deduct_funds":
        return `-${amount}`
      case "no_action":
        return `${amount}`
      default:
        return `${amount}`
    }
  }

  const getStatusClass = (status) => {
    switch (status) {
      case "Pending":
        return "pending-table"
      case "Success":
        return "win-table"
      case "Failed":
        return "lose-table"
      default:
        return "neutral-table"
    }
  }

  const parseDescription = (description) => {
    const parts = description.split("[")
    const firstPart = parts[0].trim()
    const secondPart = parts[1] ? "[" + parts[1].trim() : "" 

    return { firstPart, secondPart }
  }
  return (
    <div className="table-responsive">
      <table className="av-table">
        <thead>
          <tr>
            <th>{t("Sum")}</th>
            <th>{t("Commentary")}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td className={`amount ${getAmountClass(item.action)}`}>
                {formatAmount(item.action, item.amount)}
              </td>
              <td className="comment">
                <div className="date">
                {formatDate(
                  new Date(item.createdAt),
                  i18n.language
                )}
                </div>
                <div className="text"> {t(parseDescription(item.description).firstPart)} {parseDescription(item.description).secondPart}</div>
                <div className={`status ${getStatusClass(item.status)}`}>
                  {t(item.status)}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default TransactionTable
