import { useCallback, useMemo } from "react"
import api from "../../../api"
import { endpoints } from "../../../endpoints"
import { createQueryString } from "../../../../../utils"

const { get } = endpoints.player.game

export const PlayerGameService = () => {
  const getInitialGameInformationPlayerGameService = useCallback(
    (searchParameter, sortBy, order) => {
      try {
        return api
          .get(
            `${get.getInitialGameInformation}?${createQueryString({ searchParameter, sortBy, order })}`
          )
          .then((response) => {
            return response
          })
      } catch (_) {}
    },
    []
  )

  const getDemoInitialGameInformationPlayerGameService = useCallback(
    (searchParameter, sortBy, order) => {
      try {
        return api
          .get(
            `${get.getDemoInitialGameInformation}?${createQueryString({
              searchParameter,
              sortBy,
              order,
            })}`
          )
          .then((response) => {
            return response
          })
      } catch (_) {}
    },
    []
  )

  const getGameInformationPlayerGameService = useCallback((action, id) => {
    try {
      return api
        .get(`${get.getGameInformation}?${createQueryString({ action, id })}`)
        .then((response) => {
          return response
        })
    } catch (_) {}
  }, [])

  return useMemo(
    () => ({
      getInitialGameInformationPlayerGameService,
      getDemoInitialGameInformationPlayerGameService,
      getGameInformationPlayerGameService,
    }),
    [
      getInitialGameInformationPlayerGameService,
      getDemoInitialGameInformationPlayerGameService,
      getGameInformationPlayerGameService,
    ]
  )
}
