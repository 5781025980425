import {
  TOGGLE_MUSIC,
  SET_SOUND_FILE,
  TOGGLE__BALL_SOUND,
  TOGGLE__CLICK_SOUND,
} from "../../../app/actions/musicAction"

const initialState = {
  isPlaying: false,
  soundFile: "click", // Добавили soundFile в начальное состояние
  ballSoundIsPlaying: false,
  clickSoundIsPlaying: false,
}

const musicReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MUSIC:
      return {
        ...state,
        isPlaying: !state.isPlaying,
      }
    case SET_SOUND_FILE: // Добавляем новый case для смены soundFile
      return {
        ...state,
        soundFile: action.payload, // Обновляем soundFile на переданное значение
      }
    case TOGGLE__BALL_SOUND:
      return {
        ...state,
        ballSoundIsPlaying: !state.ballSoundIsPlaying,
      }
    case TOGGLE__CLICK_SOUND:
      return {
        ...state,
        clickSoundIsPlaying: !state.clickSoundIsPlaying,
      }
    default:
      return state
  }
}

export default musicReducer
