import React from "react"
import "./paymentTable.scss"
import useGetWithdrawalsData from "../../../shared/lib/hooks/withdrawals/get-withdrawals-data.hook"
import { formatDateToDDMMYYYYHHMMStringWithIn } from "../../../shared/utils/functions/format-date-to-dd-mm-yyyy-hh-mm-string-with-in"
import { formatDateToLocalDate } from "../../../shared/utils/functions/format-date-to-local-date"
import { useTranslation } from "react-i18next"
import formatDate from "../../services/i18n/formatDatei18"

const PaymentTable = () => {
  const { data, loading } = useGetWithdrawalsData()
  const { t, i18n } = useTranslation();
  if (loading) {
    return <></>
  }

  const getStatusClass = (status) => {
    switch (status) {
      case "Pending":
        return "pending-table"
      case "Success":
        return "win-table"
      case "Failed":
        return "lose-table"
      default:
        return "neutral-table"
    }
  }

  return (
    <div className="table-responsive-payment">
      <p className="table-responsive-title">{t("Your payments to IMPS")}</p>
      <table className="av-table">
        <thead>
          <tr>
            <th>{t("Data")}</th>
            <th>{t("Amount/Status")}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td className="comment">
                <div className="orderId">{"ID " + item.id}</div>
                <div className="date">
                  {/* {formatDateToDDMMYYYYHHMMStringWithIn(
                    formatDateToLocalDate(new Date(item.createdAt))
                  )} */}
                  {formatDate(
                  new Date(item.createdAt),
                  i18n.language
                )}
                </div>
                <div className="amount">{item.amountBeforeFee}</div>
              </td>
              <td className="amount-td">
                {item.amountAfterFee}
                <div className={`text ${getStatusClass(item.status)}`}>
                  {item.status}
                </div>
                <div className="orderId">{"ID " + item.orderId}</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default PaymentTable
