import React from "react"
import { RefillBalanceCard } from "../../../shared/ui"
import { depositsEnum } from "../../../shared/utils"
import "./refillBalanceGrid.scss"
import { useTranslation } from "react-i18next"

const RefillBalanceGrid = () => {
  const {t} = useTranslation()
  return (
    <div className="select-pay">
      <p>{t("Choose a convenient method:")}</p>
      <div className="select-pay-main-grid">
        {/* <RefillBalanceCard
          link={`/pay/${depositsEnum.UPI_FAST}`}
          iconType="upi_fast"
          text={"INR (Upi Fast)"}
        />
        <RefillBalanceCard
          link={`/pay/${depositsEnum.UPI_FAST_V}`}
          iconType="upi_fast_v"
          text={"INR (Upi Fast V)"}
        /> */}
        <RefillBalanceCard
          link={`/pay/${depositsEnum.UPI_P2P}`}
          iconType="upi_p2p"
          text={"INR (Upi P2P)"}
        />
        <RefillBalanceCard
          link={`/pay/${depositsEnum.PAYTM}`}
          iconType="paytm"
          text={"INR (PayTm)"}
        />
        <RefillBalanceCard
          link={`/pay/${depositsEnum.PHONEPE}`}
          iconType="phonepe"
          text={"INR (PhonePe)"}
        />
        {/* <RefillBalanceCard
          link={`/pay/${depositsEnum.UPI_PUSH}`}
          iconType="upi_push"
          text={"INR (Upi Push)"}
        /> */}
      </div>
    </div>
  )
}

export default RefillBalanceGrid
