const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const IS_PROXY_CONNECTION = process.env.REACT_APP_IS_PROXY_CONNECTION === "true"

export const endpoints = {
  general: {
    auth: {
      post: {
        playerAuth: !IS_PROXY_CONNECTION
          ? `${BACKEND_URL}/api/auth/player`
          : `/api/auth/player`,
        refreshTokenAccess: !IS_PROXY_CONNECTION
          ? `${BACKEND_URL}/api/auth/refresh-access`
          : `/api/auth/refresh-access`,
        logout: !IS_PROXY_CONNECTION
          ? `${BACKEND_URL}/api/auth/logout`
          : `/api/auth/logout`,
      },
      get: {
        getMe: !IS_PROXY_CONNECTION
          ? `${BACKEND_URL}/api/auth/me`
          : `/api/auth/me`,
      },
    },
    common: {
      get: {
        ping: !IS_PROXY_CONNECTION
          ? `${BACKEND_URL}/api/common/ping`
          : `/api/common/ping`,
      },
    },
  },
  player: {
    auth: {},
    game: {
      post: {
        makeBet: !IS_PROXY_CONNECTION
          ? `${BACKEND_URL}/api/game/make-bet`
          : `/api/game/make-bet`,
      },
      patch: {
        makeCashout: !IS_PROXY_CONNECTION
          ? `${BACKEND_URL}/api/game/make-cashout`
          : `/api/game/make-cashout`,
      },
      remove: {
        makeCancel: !IS_PROXY_CONNECTION
          ? `${BACKEND_URL}/api/game/make-cancel`
          : `/api/game/make-cancel`,
      },
      get: {
        getInitialGameInformation: !IS_PROXY_CONNECTION
          ? `${BACKEND_URL}/api/game/initial`
          : `/api/game/initial`,
        getDemoInitialGameInformation: !IS_PROXY_CONNECTION
          ? `${BACKEND_URL}/api/game/demo-initial`
          : `/api/game/demo-initial`,
        getGameInformation: !IS_PROXY_CONNECTION
          ? `${BACKEND_URL}/api/game`
          : `/api/game`,
        getMyActualBalance: !IS_PROXY_CONNECTION
          ? `${BACKEND_URL}/api/game/my-actual-balance`
          : `/api/game/my-actual-balance`,
        getMyDemoActualBalance: !IS_PROXY_CONNECTION
          ? `${BACKEND_URL}/api/game/my-demo-actual-balance`
          : `/api/game/my-demo-actual-balance`,
      },
    },
    players: {
      get: {
        getPhoto: !IS_PROXY_CONNECTION
          ? `${BACKEND_URL}/api/player/players/photo`
          : `/api/player/players/photo`,
        getPhotoById: !IS_PROXY_CONNECTION
          ? `${BACKEND_URL}/api/player/players/photo`
          : `/api/player/players/photo`,
      },
    },
    withdrawals: {
      get: {
        getPlayerWithdrawalsForPaymentSystem: !IS_PROXY_CONNECTION
          ? `${BACKEND_URL}/api/player/withdrawals`
          : `/api/player/withdrawals`,
        getPlayerWithdrawalAmount: !IS_PROXY_CONNECTION
          ? `${BACKEND_URL}/api/player/withdrawals/amount`
          : `/api/player/withdrawals/amount`,
        aPayGetPaymentSystemLimitationWithSystemLimitationWithdrawal:
          !IS_PROXY_CONNECTION
            ? `${BACKEND_URL}/api/player/withdrawals/a-pay/payment-system-limitation`
            : `/api/player/withdrawals/a-pay/payment-system-limitation`,
      },
      post: {
        aPayCreateWithdrawal: !IS_PROXY_CONNECTION
          ? `${BACKEND_URL}/api/player/withdrawals/a-pay/create-withdrawal`
          : `/api/player/withdrawals/a-pay/create-withdrawal`,
      },
    },
    deposits: {
      get: {
        aPayGetPaymentSystemLimitationDeposit: !IS_PROXY_CONNECTION
          ? `${BACKEND_URL}/api/player/deposits/a-pay/payment-system-limitation`
          : `/api/player/deposits/a-pay/payment-system-limitation`,
      },
      post: {
        aPayCreatePage: !IS_PROXY_CONNECTION
          ? `${BACKEND_URL}/api/player/deposits/a-pay/create-page`
          : `/api/player/deposits/a-pay/create-page`,
      },
    },
    transactions: {
      get: {
        getMyTransactionHistory: !IS_PROXY_CONNECTION
          ? `${BACKEND_URL}/api/player/transactions/my-history`
          : `/api/player/transactions/my-history`,
      },
    },
  },
}