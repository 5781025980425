import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import {
  makeBet as makeBetThunk,
  makeCashout as makeCashoutThunk,
} from "../../../../features/player/model/thunks"
import {
  setBet,
  setDefaultBet,
  setDefaultWinnings,
  setIsAutoBet,
  setBetError,
  removeOldBetErrors,
  demoMakeBet,
  demoMakeCashout,
} from "../../../../features/player/model/slice"
import {
  setDefaultMyDataDurationCurrentGamingSession,
  playerMakeBet,
  playerMakeCashout,
} from "../../../../features/gaming-session/model/slice"
import { DEFAULT_AVATAR_CONST } from "../../../utils"
import useGamingSession from "../../../../shared/lib/hooks/gaming-session/gaming-session.hook"
import usePlayer from "../../../../shared/lib/hooks/player/player.hook"
import {
  FIRST_BUT_NUMB,
  requestActionEnum,
  MAX_BET_VALUE,
  MIN_BET_VALUE,
} from "../../../../shared/utils"

const useBet = () => {
  const dispatch = useDispatch()
  const {
    myDataDurationCurrentGamingSession,
    isGamingSessionInitialized,
    isGamingSessionFinished,
    currentGamingSession,
    currentFrontendMultiplier,
  } = useGamingSession()
  const { bet, isAutoBet, winnings, balance, betError, player } = usePlayer()
  const [isMakeBetRequestSent, setIsMakeBetRequestSent] = useState(false)

  const makeBet = (madeBet) => {
    try {
      if (
        !bet &&
        madeBet >= MIN_BET_VALUE &&
        madeBet <= MAX_BET_VALUE &&
        madeBet <= balance
      ) {
        dispatch(setBet(madeBet))
      } else if (madeBet > balance) {
        dispatch(setBetError({ time: Date.now(), message: "Not enough funds" }))
      }
    } catch (_) {}
  }

  const makeBetOrMakeCancel = (madeBet) => {
    try {
      makeBet(madeBet)
      if (!isGamingSessionInitialized && bet) {
        dispatch(setDefaultBet())
      }
    } catch (_) {}
  }

  const changeIsAutoBet = () => {
    dispatch(setIsAutoBet(!isAutoBet))
  }

  useEffect(() => {
    if (betError.length > 0) {
      const interval = setInterval(() => {
        dispatch(removeOldBetErrors())
      }, 100)

      return () => clearInterval(interval)
    }
  }, [betError, dispatch])

  useEffect(() => {
    if (!currentGamingSession) {
      dispatch(setDefaultMyDataDurationCurrentGamingSession())
    }
    if (
      currentGamingSession &&
      myDataDurationCurrentGamingSession &&
      myDataDurationCurrentGamingSession.bet &&
      !myDataDurationCurrentGamingSession.multiplier
    ) {
      dispatch(setBet(myDataDurationCurrentGamingSession.bet))
      setIsMakeBetRequestSent(true)
    }
  }, [dispatch, myDataDurationCurrentGamingSession, currentGamingSession])

  useEffect(() => {
    if (isGamingSessionFinished) {
      setIsMakeBetRequestSent(false)
      if (winnings) {
        dispatch(setDefaultWinnings())
      }
      if (isMakeBetRequestSent) {
        dispatch(setDefaultBet())
      }
    }
  }, [dispatch, isGamingSessionFinished, isMakeBetRequestSent, winnings])

  useEffect(() => {
    try {
      if (
        bet &&
        isGamingSessionInitialized &&
        !myDataDurationCurrentGamingSession
      ) {
        if (player.id) {
          dispatch(
            makeBetThunk({
              action: requestActionEnum.MAKE_BET,
              bet,
              butNumb: FIRST_BUT_NUMB,
            })
          )
        } else {
          dispatch(demoMakeBet({ bet }))
          dispatch(
            playerMakeBet({
              playerId: player.id,
              bet: bet,
              photoUri: DEFAULT_AVATAR_CONST,
              playerName: "IT IS YOU",
            })
          )
        }
        setIsMakeBetRequestSent(true)
      }
    } catch (_) {
      dispatch(setBet(null))
    }
  }, [
    dispatch,
    bet,
    isGamingSessionInitialized,
    myDataDurationCurrentGamingSession,
    player.id,
  ])

  const makeCashoutRequest = (autoCashoutOption) => {
    try {
      if (player.id) {
        dispatch(
          makeCashoutThunk({
            action: requestActionEnum.MAKE_CASHOUT,
            autoCashoutOption,
            butNumb: FIRST_BUT_NUMB,
          })
        )
      } else {
        if (autoCashoutOption) {
          const winnings = Math.floor(autoCashoutOption * bet * 100) / 100
          dispatch(
            demoMakeCashout({
              winnings: winnings,
              newBalance: balance + winnings,
            })
          )
          dispatch(
            playerMakeCashout({
              playerId: player.id,
              winnings: autoCashoutOption * bet,
              multiplier: autoCashoutOption,
            })
          )
        } else {
          const multiplier = currentFrontendMultiplier
          const winnings = Math.floor(multiplier * bet * 100) / 100
          dispatch(
            demoMakeCashout({
              winnings: winnings,
              newBalance: balance + winnings,
            })
          )
          dispatch(
            playerMakeCashout({
              playerId: player.id,
              winnings: multiplier * bet,
              multiplier: multiplier,
            })
          )
        }
      }
    } catch (_) {
    } finally {
      dispatch(setDefaultBet())
      setIsMakeBetRequestSent(false)
    }
  }

  return {
    makeCashoutRequest,
    makeBet,
    makeBetOrMakeCancel,
    isMakeBetRequestSent,
    changeIsAutoBet,
  }
}

export default useBet
