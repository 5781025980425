import { useSelector } from "react-redux"
import { gamingSessionStatusEnum } from "../../../utils"

const useGamingSession = () => {
  const {
    gamingSessionStatus,
    currentMultiplier,
    currentGamingSession,
    previousGameSessions,
    myDataDurationCurrentGamingSession,
    allDataDurationCurrentGamingSession,
    gameConsts,
    currentFrontendMultiplier,
  } = useSelector((state) => state.gamingSession)

  return {
    gamingSessionStatus: gamingSessionStatus ?? null,
    currentMultiplier: currentMultiplier ?? null,
    currentGamingSession: currentGamingSession ?? null,
    previousGameSessions: previousGameSessions ?? [],
    myDataDurationCurrentGamingSession:
      myDataDurationCurrentGamingSession ?? null,
    allDataDurationCurrentGamingSession:
      allDataDurationCurrentGamingSession ?? [],
    gameConsts: gameConsts ?? null,
    isGamingSessionInitialized:
      gamingSessionStatus === gamingSessionStatusEnum.INITIALIZED,
    isGamingSessionStarted:
      gamingSessionStatus === gamingSessionStatusEnum.STARTED,
    isGamingSessionFinished:
      gamingSessionStatus === gamingSessionStatusEnum.FINISHED,
    currentFrontendMultiplier: currentFrontendMultiplier ?? null,
  }
}

export default useGamingSession
