import { useSelector } from "react-redux"
import { DEFAULT_AVATAR_CONST } from "../../../utils"

const usePlayer = () => {
  const {
    loading,
    playerData,
    balance,
    bet,
    winnings,
    betValue,
    betValueString,
    isAutoBet,
    isConnected,
    isAutoCashoutEnabled,
    autoCashoutOption,
    stringAutoCashoutOption,
    betError,
  } = useSelector((state) => state.player)

  const formatDate = (dateTime) => {
    return dateTime ? dateTime.split(" ")[0] : ""
  }

  return {
    loading,
    player: {
      id: playerData?.player?.id ?? 0,
      telegramId: playerData?.player?.telegramId ?? 0,
      username: playerData?.player?.username ?? "",
      firstName: playerData?.player?.firstName ?? "",
      lastName: playerData?.player?.lastName ?? "",
      authDate: formatDate(playerData?.player?.authDate),
      dataVersion: playerData?.player?.dataVersion ?? 0,
      createdAt: formatDate(playerData?.player?.createdAt),
    },
    balance: balance ?? 0,
    bet: bet,
    winnings: winnings,
    photo: playerData?.photo ?? DEFAULT_AVATAR_CONST,
    betValue,
    betValueString,
    isAutoBet,
    isAutoCashoutEnabled,
    autoCashoutOption: autoCashoutOption ?? 1,
    isConnected,
    stringAutoCashoutOption,
    betError: betError,
  }
}

export default usePlayer
