import React from "react"
import "./navigationButton.scss"
import { Icon } from "../../"
import { Link } from "react-router-dom"

const NavigationButton = ({
  text,
  onClick,
  link,
  iconType,
  width,
  height,
  alt_text,
  color,
  textDecoration,
}) => {
  return (
    <Link to={link} className="navigation-block" rel="noopener noreferrer">
      <button className="navigation-button" onClick={onClick}>
        <Icon
          type={iconType}
          width={width}
          height={height}
          color={color}
          alt={alt_text}
        />
        <span style={{ color: color, textDecoration: textDecoration }}>
          {text}
        </span>
      </button>
    </Link>
  )
}

export default NavigationButton
