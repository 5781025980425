import { useCallback } from "react"
import { handleDisconnect } from "../../../api/socket.io/handlers/disconnect.handler"
import { useDispatch } from "react-redux"

const useDisconnectHandler = () => {
  const dispatch = useDispatch()
  const handleDisconnectCallback = useCallback(
    () => handleDisconnect(dispatch),
    [dispatch]
  )

  return handleDisconnectCallback
}

export default useDisconnectHandler
