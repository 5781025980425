import api from "../../../../api"
import { endpoints } from "../../../../endpoints"
import { createQueryString } from "../../../../../../utils"

const { post, patch, remove, get } = endpoints.player.game

const makeBetPlayerGameService = ({ action, bet, butNumb }) => {
  try {
    return api
      .post(post.makeBet, {
        action,
        bet,
        butNumb,
      })
      .then((response) => {
        return response
      })
  } catch (_) {}
}

const makeCashoutPlayerGameService = ({
  action,
  autoCashoutOption,
  butNumb,
}) => {
  try {
    return api
      .patch(patch.makeCashout, {
        action,
        autoCashoutOption,
        butNumb,
      })
      .then((response) => {
        return response
      })
  } catch (_) {}
}

const makeCancelPlayerGameService = ({ action, butNumb }) => {
  try {
    return api
      .delete(remove.makeCancel, {
        data: {
          action,
          butNumb,
        },
      })
      .then((response) => {
        return response
      })
  } catch (_) {}
}

const getMyActualBalanceStoreGameService = (action) => {
  try {
    return api
      .get(
        `${get.getMyActualBalance}?${createQueryString({
          action,
        })}`
      )
      .then((response) => {
        return response
      })
  } catch (_) {}
}

const getMyDemoActualBalanceStoreGameService = (action) => {
  try {
    return api
      .get(
        `${get.getMyDemoActualBalance}?${createQueryString({
          action,
        })}`
      )
      .then((response) => {
        return response
      })
  } catch (_) {}
}

export const StoreGameService = {
  makeBetPlayerGameService,
  makeCashoutPlayerGameService,
  makeCancelPlayerGameService,
  getMyActualBalanceStoreGameService,
  getMyDemoActualBalanceStoreGameService,
}
