import { useState } from "react"
import { PlayerDepositsService } from "../../../../shared/api/axios/requests/player"
import useHttpHook from "../../../../shared/lib/hooks/use-http/http.hook"
import { currencyEnum } from "../../../utils"

const useAPayCreatePage = () => {
  const { aPayCreatePagePlayerDepositsService } = PlayerDepositsService()
  const { request, loading } = useHttpHook()
  const [error, setError] = useState(undefined)

  const createPage = async (amount, paymentSystem) => {
    try {
      const data = await request(() => {
        return aPayCreatePagePlayerDepositsService({
          amount,
          currency: currencyEnum.INR,
          paymentSystems: [paymentSystem],
        })
      })

      if (data.error && data.message && data.statusCode) {
        setError(data.message)
        setTimeout(() => {
          setError(undefined)
        }, 5000)
      } else if (data.statusCode === 429) {
        setError("Too Many Requests")
        setTimeout(() => {
          setError(undefined)
        }, 5000)
      }

      window.location.href = data.data.data.url
    } catch (_) {}
  }

  return {
    createPage,
    error,
    loading,
  }
}

export default useAPayCreatePage
