import React from "react"
import "./backTelegram.scss"
import { useTranslation } from "react-i18next"
const BackTelegram = () => {
  const { t } = useTranslation()

  return (
    <div className="back-telegram-block">
      <p>
        {t(
          "Registration and the game process takes place in the Telegram messenger"
        )}
        .
      </p>
      <p>
        {t(
          "By clicking the “Go to Cricket Jet Bot” button, you confirm that you are of legal age"
        )}
        .
      </p>
      <p> {t("To get started, click here")}:</p>
      <p className="back-telegram-link">
        {" "}
        <a target="_blank" href="https://t.me/cricket_jet_bot" rel="noopener noreferrer">
          {t("Go to Cricket Jet Bot")}
        </a>
      </p>
      <p>
        {t(
          "To contact technical support, click on the “Support” tab within Cricket Jet. Support” tab within the Cricket Jet game"
        )}
        .
      </p>
      <p>
        {t("For out-of-game communication, use e-mail")}:
        <br />
        cricketbetindia@gmail.com
      </p>
      {/* <p>
        <a href="/?menu=static&amp;file=agreement" className="footer-link">
          {t("User Agreement")}
        </a>
      </p>
      <p>
        <a href="/?menu=static&amp;file=conf" className="footer-link">
          {t("Privacy Policy")}
        </a>
      </p> */}
    </div>
  )
}

export default BackTelegram
