import { useMemo } from "react"
import useGamingSessionStateHandler from "./gaming-session-state-handler.hook"
import useConnectHandler from "./connect-handler.hook"
import useDisconnectHandler from "./disconnect-handler.hook"
import { events } from "../../../api/socket.io/events"
const useSocketEventHandlers = () => {
  const handleDisconnect = useDisconnectHandler()
  const handleConnect = useConnectHandler()
  const handleGamingSessionState = useGamingSessionStateHandler()

  const socketHandlers = useMemo(
    () => [
      { event: events.connect, handler: handleConnect },
      { event: events.disconnect, handler: handleDisconnect },
      { event: events.gamingSessionState, handler: handleGamingSessionState },
    ],
    [handleConnect, handleDisconnect, handleGamingSessionState]
  )

  return socketHandlers
}

export default useSocketEventHandlers
