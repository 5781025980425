import React from "react"
import "./main-game-little-button.scss"

const MainGameLittleButton = ({ onClick, text }) => {
  return (
    <button onClick={onClick} className="main-game-little-button">
      {text}
    </button>
  )
}

export default MainGameLittleButton
