import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { PlayerGameService } from "../../../../shared/api/axios/requests/player"
import useHttpHook from "../../../../shared/lib/hooks/use-http/http.hook"
import { initGamingSessionData } from "../../../../features/gaming-session/model/slice"
import { TokenService } from "../../../services"

const useInitialGamingSessionData = () => {
  const dispatch = useDispatch()
  const {
    getInitialGameInformationPlayerGameService,
    getDemoInitialGameInformationPlayerGameService,
  } = PlayerGameService()
  const { request } = useHttpHook()
  const [
    isLoadingInitialGamingSessionData,
    setIsLoadingInitialGamingSessionData,
  ] = useState(true)

  useEffect(() => {
    async function fetchData() {
      try {
        if (TokenService.getLocalAccessToken()) {
          const {
            data: { data },
          } = await request(() => getInitialGameInformationPlayerGameService())
          dispatch(initGamingSessionData(data))
        } else {
          const {
            data: { data },
          } = await request(() =>
            getDemoInitialGameInformationPlayerGameService()
          )
          dispatch(initGamingSessionData(data))
        }
      } catch (_) {
      } finally {
        setIsLoadingInitialGamingSessionData(false)
      }
    }
    fetchData()
  }, [
    getInitialGameInformationPlayerGameService,
    getDemoInitialGameInformationPlayerGameService,
    request,
    dispatch,
  ])

  return { isLoadingInitialGamingSessionData }
}

export default useInitialGamingSessionData
