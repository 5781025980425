import React, { useEffect } from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import { ProfileHeader, ProfileFooter } from "../../widgets"
import {
  MainPage,
  ProfilePage,
  RefillPage,
  OrderPaymentPage,
  TransactionPage,
  PayPage,
  BackTelegramPage,
} from "../../pages"
import InvalidLinkPage from "../../pages/invalidLinkPage/ui"
import { useLocation } from "react-router-dom";

const ProfileHeaderFooter = ({ element }) => {
  const location = useLocation();

  useEffect(() => {
    const allowScrollPaths = ["/profile", "/refill", "/payment", "/transaction", "/pay/:paymentSystem"];

    const isAllowScroll = allowScrollPaths.some((path) => {
      return path.includes(":")
        ? location.pathname.startsWith(path.split(":")[0]) 
        : location.pathname === path;
    });

    if (isAllowScroll) {
      document.body.classList.add("allow-scroll");
    } else {
      document.body.classList.remove("allow-scroll");
    }

    return () => {
      document.body.classList.remove("allow-scroll");
    };
  }, [location.pathname]);
  return (
    <>
      <ProfileHeader />
      <style>{`html{background-color: #02021b;}`}</style>
      {element}
      <ProfileFooter />
    </>
  )
}
const InfoTelegramWrapper = ({ element }) => {
  useEffect(() => {
    document.documentElement.style.backgroundColor = "#02021b"
    return () => {
      document.documentElement.style.backgroundColor = ""
    }
  }, [])

  return element
}
export const useRoutes = (playerData, isPlayerDataReceivedOrCleared) => {
  return (
    isPlayerDataReceivedOrCleared && (
      <Routes>
        <Route path="/GAME_ENGINE" exact element={<MainPage />} />
        <Route path="/invalid-link" exact element={<InvalidLinkPage />} />
        {playerData && playerData.player && (
          <Route
            path="/profile"
            exact
            element={<ProfileHeaderFooter element={<ProfilePage />} />}
          />
        )}
        {playerData && playerData.player && (
          <Route
            path="/refill"
            exact
            element={<ProfileHeaderFooter element={<RefillPage />} />}
          />
        )}
        {playerData && playerData.player && (
          <Route
            path="/payment"
            exact
            element={<ProfileHeaderFooter element={<OrderPaymentPage />} />}
          />
        )}
        {playerData && playerData.player && (
          <Route
            path="/transaction"
            exact
            element={<ProfileHeaderFooter element={<TransactionPage />} />}
          />
        )}
        {playerData && playerData.player && (
          <Route
            path="/pay/:paymentSystem"
            exact
            element={<ProfileHeaderFooter element={<PayPage />} />}
          />
        )}

        {!playerData || !playerData.player ? (
          <Route
            path="/info-telegram"
            exact
            element={<InfoTelegramWrapper element={<BackTelegramPage />} />}
          />
        ) : null}
        <Route path="/*" element={<Navigate replace to="/GAME_ENGINE" />} />
      </Routes>
    )
  )
}
