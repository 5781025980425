import React, { useEffect, useState } from "react"
import "./main-game-center.scss"
import useGamingSession from "../../../../lib/hooks/gaming-session/gaming-session.hook"
import Lottie from "react-lottie"
import { motion } from "framer-motion"
import backgroundSVG from "./animation/background_first_stage1.svg"

import backgroundSpaceSVG from "./animation/space.svg"
import CharIDLEAnimation from "./animation/char_seat_12.json"
import CharHIT from "./animation/char-beat_13.json"
import Funs1 from "./animation/funs1.svg"
import Funs2 from "./animation/funs2.svg"
import Funs3 from "./animation/funs3.svg"
import Clouds from "./animation/clouds.svg"
import BallNormalAnimation from "./animation/ball_01.json"
import BallSuperAnimation from "./animation/ball_super.json"
import { useDispatch } from "react-redux"
import {
  setSoundFile,
  toggleBallSound,
} from "../../../../../app/actions/musicAction"

const variants = {
  open: { y: 0, x: 0 },
  closed: { y: 14000, x: -2950 },
  middle: { y: 3500, x: -1475 },
  startgame: { y: 3500, x: -1475 },
  supergame: { y: 3500, x: -1475 },
}

const variantsSpace = {
  open: { y: 0 },
  closed: { y: 7000 },
}

const ballExit = {
  hit: { y: "5%", x: "-50%" },
  fly: { y: -1000, x: 500 },
  prep: { y: "5%", x: 400 },
  middle: { y: "5%", x: "-50%" },
  startgame: { y: "5%", x: "-50%" },
}

const ballSuperGameExit = {
  hit: { y: "5%", x: "-50%" },
  fly: { y: "-220%", x: "-50%" },
  prep: { y: "-220%", x: "-50%" },
}

const MainGameToast = ({ active }) => {
  const {
    isGamingSessionInitialized,
    isGamingSessionStarted,
    isGamingSessionFinished,
    currentMultiplier,
  } = useGamingSession()

  const [primeAnimation, setPrimeAnimation] = useState(true)
  const [ballAnimation, setBallAnimation] = useState("hit")
  const [charAnimation, setCharAnimation] = useState(true)
  const [ballRotate, setBallRotate] = useState(false)
  const [superGame, setSuperGame] = useState(false)
  const [whiteBackground, setWhiteBackground] = useState(false)
  const [whiteBackgroundOncePlayed, setWhiteBackgroundOncePlayed] =
    useState(false)
  const [initialAnimationState, setInitialAnimationState] = useState("first")

  const dispatch = useDispatch()
  const changeSound = (newSoundFile) => {
    dispatch(setSoundFile(newSoundFile)) // Меняем soundFile при вызове
  }

  const ballSound = () => {
    dispatch(toggleBallSound()) // Меняем soundFile при вызове
  }

  useEffect(() => {
    if (!superGame && currentMultiplier >= 20) {
      setSuperGame(true)
    } else if (superGame && currentMultiplier < 1) {
      const timer = setTimeout(() => {
        setSuperGame(false)
        setWhiteBackgroundOncePlayed(false)
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [superGame, currentMultiplier])

  useEffect(() => {
    if (currentMultiplier >= 19 && !whiteBackgroundOncePlayed) {
      setWhiteBackground(true)
      setWhiteBackgroundOncePlayed(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMultiplier])

  useEffect(() => {
    const timer = setTimeout(() => {
      setWhiteBackground(false)
    }, 1000)
    return () => clearTimeout(timer)
  }, [whiteBackground])

  useEffect(() => {
    if (isGamingSessionStarted) {
      setPrimeAnimation(false)
      setCharAnimation(true)
      setInitialAnimationState("")
    } else if (isGamingSessionInitialized) {
      setPrimeAnimation(true)
      setBallRotate(true)
      setTimeout(() => {
        setBallAnimation("hit")
      }, 4400)
      setTimeout(() => {
        setCharAnimation(false)
        changeSound("startHit")
      }, 4500)
      setTimeout(() => {
        setBallRotate(false)
      }, 4800)
    } else if (isGamingSessionFinished) {
      setCharAnimation(true)
      setBallAnimation("fly")
      setTimeout(() => {
        setBallAnimation("prep")
        setPrimeAnimation(true)
      }, 1000)
      if (superGame) {
        ballSound()
        changeSound("superGameEndSound")
        const timer = setTimeout(() => {
          setWhiteBackground(true)
        }, 400)
        return () => clearTimeout(timer)
      } else {
        ballSound()
        changeSound("gameEndSound")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isGamingSessionStarted,
    isGamingSessionInitialized,
    isGamingSessionFinished,
  ])

  const CharIDLE = {
    loop: true,
    autoplay: true,
    animationData: charAnimation ? CharIDLEAnimation : CharHIT,
  }

  const Ball = {
    loop: true,
    autoplay: true,
    animationData: BallNormalAnimation,
  }

  const BallSuper = {
    loop: true,
    autoplay: true,
    animationData: BallSuperAnimation,
  }

  useEffect(() => {
    if (initialAnimationState === "first") {
      if (currentMultiplier >= 19) {
        setInitialAnimationState("supergame")
      } else if (currentMultiplier >= 1) {
        setInitialAnimationState("middle")
      } else {
        setInitialAnimationState("startgame")
        setBallRotate(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialAnimationState])

  if (initialAnimationState === "first") return <></>

  return (
    <div
      className={"game__modal__animation" + (active ? " active" : "")}
      id="modal"
    >
      <div
        className={
          "blur-loading " + (initialAnimationState === "startgame" && "visible")
        }
      >
        <motion.img
          className={"backgroundSVG " + (!superGame && "background-visible")}
          src={backgroundSVG}
        />
      </div>
      <motion.div
        className={"char-wrapper" + (primeAnimation ? " short-transition" : " long-transition")}
        animate={primeAnimation ? "open" : "closed"}
        initial={initialAnimationState}
        variants={variants}
        transition={{ duration: 0, ease: "linear" }}>
        <Lottie options={CharIDLE} height={600} width={480} />
      </motion.div>
      {superGame ? (
        <motion.div
          className="ball-wrapper"
          animate={ballAnimation}
          variants={ballSuperGameExit}
          transition={{ duration: 0.5, ease: "linear" }}
        >
          <div>
            <Lottie options={BallSuper} height={600} width={444} />
          </div>
        </motion.div>
      ) : (
        <motion.div
          className="ball-wrapper"
          animate={ballAnimation}
          variants={ballExit}
          initial={initialAnimationState}
          transition={{
            duration: ballAnimation === "hit" ? 0.5 : 1,
            ease: "linear",
          }}
        >
          <div
            className={
              ballRotate && initialAnimationState !== "startgame"
                ? "ball-rotate-reverse"
                : "ball-rotate"
            }
          >
            <Lottie options={Ball} height={600} width={444} />
          </div>
        </motion.div>
      )}

      <motion.div
        className={"funs-wrapper" +  (primeAnimation ? " short-transition" : " long-transition")}
        animate={primeAnimation ? "open" : "closed"}
        initial={initialAnimationState}
        variants={variants}
        transition={{ duration: 0, ease: "linear" }}
      >
        <motion.img
          className="funs funs1"
          src={Funs1}
          animate={{ y: [0, 13] }} // Moves between x=0 and x=100
          transition={{
            repeat: Infinity,
            repeatType: "reverse",
            duration: 1,
            ease: "easeInOut",
          }}
        />
        <motion.img
          className="funs funs2"
          src={Funs2}
          animate={{ y: [0, 10] }} // Moves between x=0 and x=100
          transition={{
            repeat: Infinity,
            repeatType: "reverse",
            duration: 0.7,
            ease: "easeInOut",
          }}
        />
        <motion.img
          className="funs funs3"
          src={Funs3}
          animate={{ y: [0, 7] }} // Moves between x=0 and x=100
          transition={{
            repeat: Infinity,
            repeatType: "reverse",
            duration: 0.5,
            ease: "easeInOut",
          }}
        />
      </motion.div>
      <div
        className={
          "white-background " + (whiteBackground ? "visible" : "hiddan")
        }
      ></div>

      <motion.img
        className={"backgroundSVG " + (!superGame && "background-visible") + (primeAnimation ? " short-transition" : " long-transition")}
        src={backgroundSVG}
        animate={primeAnimation ? "open" : "closed"}
        initial={initialAnimationState}
        variants={variants}
        transition={{ duration: 0,  ease: "linear" }}
      />
      {/* <motion.img
        className={"backgroundSVG-clouds " + (!superGame && "background-visible")}
        src={Clouds}
        animate={primeAnimation ? "open" : "closed"}
        initial={initialAnimationState}
        variants={variants}
        transition={{ duration: primeAnimation ? 1 : 34.5, ease: "linear" }}
      /> */}

      <motion.img
        className={"backgroundSVGSpace " + (superGame && "background-visible") + (primeAnimation ? " short-transition" : " long-transition")}
        src={backgroundSpaceSVG}
        animate={superGame ? "closed" : "open"}
        initial={initialAnimationState}
        variants={variantsSpace}
        transition={{ duration: 0, ease: "linear" }}
      />
      
    </div>
  )
}

export default MainGameToast
