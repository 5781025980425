import { createSlice } from "@reduxjs/toolkit"
import { getMyData, makeBet, makeCashout, logout } from "./thunks"

const initialState = {
  loading: false,
  playerData: null,
  error: null,
  success: false,
  isPlayerDataReceivedOrCleared: false,
  isConnected: false,
  balance: null,
  bet: null,
  winnings: null,
  betValue: null,
  betValueString: "1",
  isAutoBet: false,
  betError: [],
  stringAutoCashoutOption: "1",
  isAutoCashoutEnabled: false,
  autoCashoutOption: null,
}

const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    connect(state) {
      state.isConnected = true
    },
    disconnect(state) {
      state.isConnected = false
    },
    setBet(state, action) {
      state.bet = action.payload
    },
    setBetValue(state, action) {
      state.betValue = action.payload
    },
    setNewBalance(state, action) {
      state.balance = action.payload
    },
    setBetValueString(state, action) {
      state.betValueString = action.payload
    },
    setIsAutoBet(state, action) {
      state.isAutoBet = action.payload
    },
    setBetError(state, action) {
      const newErrors = Array.isArray(action.payload)
        ? action.payload
        : [action.payload]
      state.betError = [...newErrors, ...state.betError].slice(0, 8)
    },
    removeOldBetErrors(state) {
      state.betError = state.betError.filter(
        (error) => error.time >= Date.now() - 3000
      )
    },
    enableAutoCashout(state) {
      state.isAutoCashoutEnabled = true
    },
    disableAutoCashout(state) {
      state.isAutoCashoutEnabled = false
    },
    setAutoCashoutOption(state, action) {
      state.autoCashoutOption = action.payload.autoCashoutOption
    },
    setStringAutoCashoutOption(state, action) {
      state.stringAutoCashoutOption = action.payload
    },
    setDefaultBalance(state) {
      state.balance = initialState.balance
    },
    setDefaultBet(state) {
      state.bet = initialState.bet
    },
    setDefaultWinnings(state) {
      state.winnings = initialState.winnings
    },
    demoMakeBet(state, action) {
      state.bet = action.payload.bet
      state.balance = state.balance - action.payload.bet
    },
    demoMakeCashout(state, action) {
      state.winnings = action.payload.winnings
      state.balance = action.payload.newBalance
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyData.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
        state.isPlayerDataReceivedOrCleared = false
      })
      .addCase(getMyData.fulfilled, (state, { payload }) => {
        state.loading = false
        state.playerData = { player: payload.player, photo: payload.photo }
        state.balance = payload.balance
        state.success = true
        state.isPlayerDataReceivedOrCleared = true
      })
      .addCase(getMyData.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
        state.isPlayerDataReceivedOrCleared = true
      })
      .addCase(makeBet.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(makeBet.fulfilled, (state, { payload }) => {
        state.loading = false
        state.bet = payload.bet
        state.balance = payload.newBalance
        state.success = true
      })
      .addCase(makeBet.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
      })
      .addCase(makeCashout.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(makeCashout.fulfilled, (state, { payload }) => {
        state.loading = false
        state.winnings = payload.winnings
        state.balance = payload.newBalance
        state.bet = null
        state.success = true
      })
      .addCase(makeCashout.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
      })
      .addCase(logout.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
        state.isPlayerDataReceivedOrCleared = false
      })
      .addCase(logout.fulfilled, (state) => {
        state.loading = false
        state.playerData = null
        state.bet = null
        state.winnings = null
        state.betValue = null
        state.betValueString = "1"
        state.isAutoBet = false
        state.betError = []
        state.stringAutoCashoutOption = "1"
        state.isAutoCashoutEnabled = false
        state.autoCashoutOption = null
        state.success = true
        state.isPlayerDataReceivedOrCleared = true
      })
      .addCase(logout.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
        state.isPlayerDataReceivedOrCleared = true
      })
  },
})

export const {
  connect,
  disconnect,
  setBet,
  setIsAutoBet,
  setDefaultBalance,
  setDefaultBet,
  setDefaultWinnings,
  enableAutoCashout,
  disableAutoCashout,
  setAutoCashoutOption,
  setBetValue,
  setBetValueString,
  setStringAutoCashoutOption,
  setBetError,
  removeOldBetErrors,
  demoMakeBet,
  demoMakeCashout,
  setNewBalance,
} = playerSlice.actions

export default playerSlice.reducer
