import React from "react"
import { PayContent } from "../../../widgets"
import "../../styleMain.scss"
const PayPage = () => {
  return (
    <div className="account-wrapper-background">
      <div className="account-wrapper">
        <div className="main-wrapper">
          <PayContent />
        </div>
      </div>
    </div>
  )
}

export default PayPage
