import { useCallback, useMemo } from "react"
import api from "../../../api"
import { endpoints } from "../../../endpoints"

const { get } = endpoints.player.players

export const PlayerPlayersService = () => {
  const getPhotoByIdPlayerPlayersService = useCallback((id) => {
    try {
      return api
        .get(`${get.getPhotoById}/${id}`, {
          responseType: "blob",
        })
        .then((response) => {
          return response
        })
    } catch (_) {}
  }, [])

  return useMemo(
    () => ({
      getPhotoByIdPlayerPlayersService,
    }),
    [getPhotoByIdPlayerPlayersService]
  )
}
