export function getQueryParams(query) {
  if (!query) {
    return undefined
  }

  const queryParams = query
    .substring(1)
    .split("&")
    .reduce((acc, param) => {
      const [key, value] = param.split("=")
      acc[key] = decodeURIComponent(value)
      return acc
    }, {})

  return Object.keys(queryParams).length > 0 ? queryParams : undefined
}
