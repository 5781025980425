import React from "react"
import "./main-game-checkbox.scss"

const MainGameCheckbox = ({
  isActive,
  omClickDecrement,
  omClickIncrement,
  value,
  text,
  handleChange,
}) => {
  return (
    <div className="quantity-wrapper">
      <button
        id="avtocash_1_minus"
        onClick={omClickDecrement}
        className="quantity-button quantity-button-minus"
      >
        -
      </button>
      <div className="quantity-input">
        <input
          id="avtocash-field-1"
          type="text"
          className="quanity-input"
          value={value}
          onChange={handleChange}
        />
      </div>
      <button
        id="avtocash-1-plus"
        onClick={omClickIncrement}
        className="quantity-button quantity-button-plus"
      >
        +
      </button>
    </div>
  )
}

export default MainGameCheckbox
