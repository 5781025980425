function calculateExponentialGrowthRate(elapsed, start, end, durationSec) {
  const rate = Math.log(end / start) / durationSec
  return start * Math.exp(rate * elapsed)
}

function calculateExponentialElapsedTime(value, start, end, durationSec) {
  const rate = Math.log(end / start) / durationSec
  return Math.log(value / start) / rate
}

export { calculateExponentialGrowthRate, calculateExponentialElapsedTime }
