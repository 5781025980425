import React from "react"
import "./transactionInfo.scss"
import { TransactionTable, BackButton } from "../../../shared/ui"
import { useTranslation } from "react-i18next"

const TransactionInfo = () => {
  const {t} = useTranslation()
  return (
    <div className="transaction-section">
      <p>
        {t("This section displays your transaction history. History records are kept for 60 days.")}
      </p>
      <TransactionTable />
      <BackButton
        iconType="leftArrow"
        text={t("Back to personal cabinet")}
        link="/profile"
        alt_text="back-icon"
        width={26}
        height={26}
        color="#8689ac"
      />
    </div>
  )
}

export default TransactionInfo
