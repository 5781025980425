import React from "react"
import { OrderPaymentGrid } from "../../../widgets"
import "../../styleMain.scss"

const OrderPaymentPage = () => {
  return (
    <div className="account-wrapper-background">
      <div className="account-wrapper">
        <div className="main-wrapper">
          <OrderPaymentGrid />
        </div>
      </div>
    </div>
  )
}

export default OrderPaymentPage
