import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import bgAudio from "../../shared/assets/audio/bg.mp3"

const BackgroundMusic = () => {
  const isPlaying = useSelector((state) => state.sound.isPlaying)

  const audio = React.useRef(new Audio(bgAudio))

  useEffect(() => {
    const audioElement = audio.current
    audioElement.volume = 0.05
    audioElement.loop = true

    if (isPlaying) {
      audioElement.play()
    } else {
      audioElement.pause()
    }

    return () => {
      audioElement.pause()
    }
  }, [isPlaying])

  return null
}

export default BackgroundMusic
