import React from "react"
import "./main-game-checkbox.scss"

const MainGameCheckbox = ({ isActive, onChange, text }) => {
  return (
    <label className={"main-game-checkbox" + (isActive ? " active" : "")}>
      <span>{text}</span>
      <input
        onChange={onChange}
        className="checkbox__input"
        type="checkbox"
        name="checkbox2"
        id="avtoplay_1"
      ></input>
    </label>
  )
}

export default MainGameCheckbox
