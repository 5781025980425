import { useCallback, useMemo } from "react"
import api from "../../../api"
import { endpoints } from "../../../endpoints"
import { TokenService, ModeService } from "../../../../../services"

const { post } = endpoints.general.auth

export const AuthService = () => {
  const playerAuthAuthService = useCallback(({ token, mode }) => {
    try {
      return api
        .post(
          post.playerAuth,
          {
            token,
            mode,
          },
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          TokenService.setLocalAccessToken(response.data.accessToken)
          ModeService.setLocalMode(mode)
          return response
        })
    } catch (_) {}
  }, [])

  const refreshTokenAccessAuthService = useCallback(async () => {
    try {
      const { data } = await api.post(
        post.refreshTokenAccess,
        {},
        { withCredentials: true }
      )
      const { accessToken } = data
      TokenService.updateLocalAccessToken(accessToken)
    } catch (_) {}
  }, [])

  return useMemo(
    () => ({
      playerAuthAuthService,
      refreshTokenAccessAuthService,
    }),
    [playerAuthAuthService, refreshTokenAccessAuthService]
  )
}
