export const emitActionEnum = {
  GAMING_SESSION: {
    INIT: "gamingSessionInit",
    START: "gamingSessionStart",
    FINISH: "gamingSessionFinish",
  },
  CURRENT_MULTIPLIER: "currentMultiplier",
  PLAYER: {
    MAKE_BET: "playerMakeBet",
    MAKE_CASHOUT: "playerMakeCashout",
    CANCEL_BET: "playerCancelBet",
  },
}
