import { useCallback, useMemo } from "react"
import api from "../../../api"
import { endpoints } from "../../../endpoints"

const { get, post } = endpoints.player.deposits

export const PlayerDepositsService = () => {
  const aPayGetPaymentSystemLimitationDepositPlayerDepositsService =
    useCallback((paymentSystem) => {
      try {
        return api
          .get(`${get.aPayGetPaymentSystemLimitationDeposit}/${paymentSystem}`)
          .then((response) => {
            return response
          })
      } catch (_) {}
    }, [])

  const aPayCreatePagePlayerDepositsService = useCallback(
    ({ amount, currency, paymentSystems }) => {
      try {
        return api
          .post(post.aPayCreatePage, {
            amount,
            currency,
            paymentSystems,
          })
          .then((response) => {
            return response
          })
      } catch (_) {}
    },
    []
  )

  return useMemo(
    () => ({
      aPayGetPaymentSystemLimitationDepositPlayerDepositsService,
      aPayCreatePagePlayerDepositsService,
    }),
    [
      aPayGetPaymentSystemLimitationDepositPlayerDepositsService,
      aPayCreatePagePlayerDepositsService,
    ]
  )
}
