import React from "react"
import { Link } from "react-router-dom"
import "./menuItem.scss"
import { Icon } from "../../"

const MenuItem = ({ iconType, text, link, onClick, alt_text, target }) => {
  return (
    <li className="side-menu-item">
      <Link
        to={link}
        className="side-menu-link"
        onClick={onClick}
        rel="noopener noreferrer"
        target={target}
      >
        <Icon
          type={iconType}
          width={30}
          height={30}
          color="#8689ac"
          alt={alt_text}
        />
        <span>{text}</span>
      </Link>
    </li>
  )
}

export default MenuItem
