import { useDispatch, useSelector } from "react-redux"
import { getMyData } from "../../../../features/player/model/thunks"
import { useCallback, useEffect, useState } from "react"
import { AuthService } from "../../../../shared/api/axios/requests/general"
import { getQueryParams, clearQueryParams } from "../../../../shared/utils"
import useHttpHook from "../../../../shared/lib/hooks/use-http/http.hook"

const useInitialAuthorization = () => {
  const dispatch = useDispatch()
  const { playerAuthAuthService } = AuthService()
  const { request } = useHttpHook()
  const [isLinkInvalid, setIsLinkInvalid] = useState(false)
  const [isLoadingPlayerAuthorization, setIsLoadingPlayerAuthorization] =
    useState(true)
  const [isDataFetched, setIsDataFetched] = useState(false)

  const fetchData = useCallback(async () => {
    try {
      const { token, mode } = getQueryParams(window.location.search) || {}
      if (token && mode) {
        await request(() => playerAuthAuthService({ token, mode }))
        clearQueryParams()
      }
    } catch (_) {
      setIsLinkInvalid(true)
    } finally {
      setIsLoadingPlayerAuthorization(false)
      setIsDataFetched(true)
    }
  }, [playerAuthAuthService, request])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const { playerData, isPlayerDataReceivedOrCleared } = useSelector(
    (state) => state.player
  )
  const { engine } = getQueryParams(window.location.search) || {}

  useEffect(() => {
    if (isDataFetched) {
      dispatch(getMyData({ isDemo: engine && engine === "DEMO" }))
    }
  }, [dispatch, engine, isDataFetched])

  return {
    playerData,
    isPlayerDataReceivedOrCleared,
    isLinkInvalid,
    isLoadingPlayerAuthorization,
  }
}

export default useInitialAuthorization
