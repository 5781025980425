import { useDispatch } from "react-redux"
import { useCallback } from "react"
import { handleGamingSessionState } from "../../../api/socket.io/handlers/gaming-session-state.handler"

const useGamingSessionStateHandler = () => {
  const dispatch = useDispatch()

  const handleGamingSessionStateCallback = useCallback(
    (event) => handleGamingSessionState(event, dispatch),
    [dispatch]
  )

  return handleGamingSessionStateCallback
}

export default useGamingSessionStateHandler
