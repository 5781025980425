import React, { useState } from "react"
import "./main-game-header-balance.scss"
import { Link } from "react-router-dom"
import usePlayer from "../../../shared/lib/hooks/player/player.hook"
import { Icon, ChangeLanguage } from "../../../shared/ui"
import { useTranslation } from "react-i18next"

const MainPageBalance = () => {
  const [popupMenu, setPopupMenu] = useState(false)
  const { balance: balanceValue } = usePlayer()
  const {t} = useTranslation()

  return (
    <div className={"header-balance" + (popupMenu ? " active" : "")}>
      <Icon
        className="header-balance-icon"
        type="wallet"
        height={28}
        width={28}
        color="white"
        alt="walletIcon"
      />
      <span className="header-balance-sum ">
        <span className="user_balance">{balanceValue.toFixed(2)}</span> INR
      </span>
      <div
        className={"header-balance-open" + (popupMenu ? " active" : "")}
        onClick={() => setPopupMenu(!popupMenu)}
      >
        <Icon
          type="arrowHeader"
          height={28}
          width={28}
          color="white"
          alt="arrowHeader-icon"
        />
      </div>
      <div className={"header-balance-menu" + (popupMenu ? " active" : "")}>
        <Link
          to="/refill"
          className="header-balance-menu-item"
          rel="noopener noreferrer"
        >
          <Icon
            type="coins"
            height={28}
            width={28}
            color="white"
            alt="money-icon"
          />{" "}
          <p>{t("Refill")}</p>
        </Link>
        <Link
          to="/payment"
          className="header-balance-menu-item"
          rel="noopener noreferrer"
        >
          <Icon
            type="withdrawal"
            height={28}
            width={28}
            color="white"
            alt="withdrawal-icon"
          />{" "}
          <p>{t("Payment")}</p>
        </Link>
        <Link
          to="/transaction"
          className="header-balance-menu-item"
          rel="noopener noreferrer"
        >
          <Icon
            type="transaction"
            height={28}
            width={28}
            color="white"
            alt="transaction-icon"
          />{" "}
          <p>{t("History")}</p>
        </Link>
        <Link
          target="_blank"
          to="https://t.me/cricketjet_support"
          className="header-balance-menu-item"
          rel="noopener noreferrer"
        >
          <Icon
            type="support"
            height={28}
            width={28}
            color="white"
            alt="support-icon"
          />{" "}
          <p>{t("Technical Support")}</p>
        </Link>
        {/* <ChangeLanguage /> */}
      </div>
    </div>
  )
}

export default MainPageBalance
