import React from "react"
import "./backButton.scss"
import { Icon } from "../../"
import { Link } from "react-router-dom"

const BackButton = ({ onClick, link, text }) => {
  return (
    <Link to={link} className="back-profile-block" rel="noopener noreferrer">
      <button className="back-profile-button" onClick={onClick}>
        <Icon
          type="leftArrow"
          alt="back-icon"
          width={26}
          height={26}
          color="#8689ac"
        />
        <span>{text}</span>
      </button>
    </Link>
  )
}

export default BackButton
