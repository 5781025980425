import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import endGameBallSound from "../../shared/assets/audio/endGameBallSound.mp3"
import { toggleBallSound } from "../../app/actions/musicAction"

const BackgroundMusic = () => {
  const ballSoundPlaying = useSelector(
    (state) => state.sound.ballSoundIsPlaying
  )
  const isPlaying = useSelector((state) => state.sound.isPlaying)

  const audio = React.useRef(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const audioElement = new Audio(endGameBallSound)
    audio.current = audioElement
    audioElement.volume = 0.05
    audioElement.loop = false

    if (ballSoundPlaying && isPlaying) {
      audioElement.play()
      dispatch(toggleBallSound())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ballSoundPlaying])

  return null
}

export default BackgroundMusic
