import { configureStore } from "@reduxjs/toolkit"
import { combineReducers } from "@reduxjs/toolkit"
import { thunk } from "redux-thunk"
import { playerReducer } from "../../features/player"
import { gamingSessionReducer } from "../../features/gaming-session"
import { soundReducer } from "../../features/sound"

const reducer = combineReducers({
  player: playerReducer,
  gamingSession: gamingSessionReducer,
  sound: soundReducer,
})

export const store = configureStore({
  reducer: reducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
})
