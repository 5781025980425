import React, { useState } from "react"
import "./game-header.scss"
import {
  MainPageActions,
  MainPageBalance,
  PopupGameInfo,
} from "../../../features"
import { MainPageLastGameBlock } from "../../../shared/ui"
import useGetGamingSessionData from "../../../shared/lib/hooks/gaming-session/get-gaming-session-data.hook"
import useGamingSession from "../../../shared/lib/hooks/gaming-session/gaming-session.hook"

const GameHeader = () => {
  const [popupGameInfoActive, setPopupGameInfoActive] = useState(false)
  const { allDataDurationCurrentGamingSession, previousGameSessions } =
    useGamingSession()
  const {
    getGamingSessionInformationById,
    gamingSessionInformation,
    isLoadingGetGamingSessionData,
  } = useGetGamingSessionData()

  return (
    <div className="game-header-wrapper">
      <div className="upper-flex">
        <MainPageBalance />
        <MainPageActions />
      </div>
      <div className="lower-flex">
        <div className="last-game-body">
          {allDataDurationCurrentGamingSession ? (
            previousGameSessions.map((gamingSession, index) => (
              <MainPageLastGameBlock
                onClick={() => {
                  setPopupGameInfoActive(!popupGameInfoActive)
                  getGamingSessionInformationById(gamingSession.id)
                }}
                value={gamingSession.finalResult}
                id={gamingSession.id}
                key={gamingSession.id}
              />
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
      <PopupGameInfo
        onClickClose={() => setPopupGameInfoActive(false)}
        active={popupGameInfoActive}
        gamingSessionInformation={gamingSessionInformation}
        isLoading={isLoadingGetGamingSessionData}
      />
    </div>
  )
}

export default GameHeader
