import React from "react"
import { AccountTitle, BackTelegram } from "../../../widgets"
import "../../styleMain.scss"
import { useTranslation } from "react-i18next"

const BackTelegramPage = () => {
  const { t } = useTranslation()
  return (
    <div className="account-wrapper-background">
      <div className="account-wrapper">
        <div className="welcome-wrapper">
          <AccountTitle text={t("Welcome to the Cricket Jet game") + "!"} />
          <BackTelegram />
        </div>
      </div>
    </div>
  )
}

export default BackTelegramPage
