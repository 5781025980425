import { useState, useCallback, useEffect } from "react"
import { PlayerTransactionsService } from "../../../../shared/api/axios/requests/player"
import useHttpHook from "../../../../shared/lib/hooks/use-http/http.hook"

const useGetMyTransactionHistory = () => {
  const { getMyTransactionHistoryPlayerTransactionsService } =
    PlayerTransactionsService()
  const { request, loading } = useHttpHook()
  const [data, setData] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [filter, setFilter] = useState({
    page: 1,
    perPage: 15,
  })

  const fetchData = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await request(() => {
        return getMyTransactionHistoryPlayerTransactionsService(filter)
      })
      setData(data.data)
      setTotalPages(data.total)
    } catch (_) {
    } finally {
    }
  }, [request, getMyTransactionHistoryPlayerTransactionsService, filter])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return { data, totalPages, loading, filter, setFilter }
}

export default useGetMyTransactionHistory
