import React from "react"
import { RefillBalanceGrid } from "../../../widgets"
import "../../styleMain.scss"

const RefillBalancePage = () => {
  return (
    <div className="account-wrapper-background">
      <div className="account-wrapper">
        <div className="main-wrapper">
          <RefillBalanceGrid />
        </div>
      </div>
    </div>
  )
}

export default RefillBalancePage
