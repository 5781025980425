import { io } from "socket.io-client"

const target = process.env.REACT_APP_BACKEND_URL
const IS_PROXY_CONNECTION = process.env.REACT_APP_IS_PROXY_CONNECTION === "true"

const socket = io(
  !IS_PROXY_CONNECTION ? `${target}/api/socket.io/game` : `/api/socket.io/game`,
  {
    autoConnect: false,
    transports: ["websocket"],
    // transports: ["websocket", "polling"],
    // withCredentials: true,
  }
)

export default socket
