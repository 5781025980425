import { useState, useCallback, useEffect } from "react"
import { PlayerWithdrawalsService } from "../../../../shared/api/axios/requests/player"
import useHttpHook from "../../../../shared/lib/hooks/use-http/http.hook"
import usePlayer from "../player/player.hook"
import { withdrawalsEnum } from "../../../utils"

const useGetWithdrawalsData = () => {
  const { getPlayerWithdrawalsForPaymentSystem } = PlayerWithdrawalsService()
  const { balance } = usePlayer()
  const { request, loading } = useHttpHook()
  const [data, setData] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [filter, setFilter] = useState({
    paymentSystem: withdrawalsEnum.IMPS,
    page: 1,
    perPage: 10,
  })
  const fetchData = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await request(() => {
        return getPlayerWithdrawalsForPaymentSystem(filter)
      })
      setData(data.data)
      setTotalPages(data.total)
    } catch (_) {
    } finally {
    }
  }, [request, getPlayerWithdrawalsForPaymentSystem, filter])

  useEffect(() => {
    fetchData()
  }, [fetchData, balance])

  return { data, totalPages, loading, filter, setFilter }
}

export default useGetWithdrawalsData
