import { useCallback, useMemo } from "react"
import api from "../../../api"
import { createQueryString } from "../../../../../utils"
import { endpoints } from "../../../endpoints"

const { get, post } = endpoints.player.withdrawals

export const PlayerWithdrawalsService = () => {
  const getPlayerWithdrawalsForPaymentSystem = useCallback(
    ({ paymentSystem, page, perPage }) => {
      try {
        return api
          .get(
            `${get.getPlayerWithdrawalsForPaymentSystem}?${createQueryString({ paymentSystem, page, perPage })}`
          )
          .then((response) => {
            return response
          })
      } catch (_) {}
    },
    []
  )

  const getPlayerWithdrawalAmountPlayerWithdrawalsService = useCallback(() => {
    try {
      return api.get(get.getPlayerWithdrawalAmount).then((response) => {
        return response
      })
    } catch (_) {}
  }, [])

  const aPayGetPaymentSystemLimitationWithSystemLimitationWithdrawalPlayerWithdrawalsService =
    useCallback((paymentSystem) => {
      try {
        return api
          .get(
            `${get.aPayGetPaymentSystemLimitationWithSystemLimitationWithdrawal}/${paymentSystem}`
          )
          .then((response) => {
            return response
          })
      } catch (_) {}
    }, [])

  const aPayCreateWithdrawalPlayerWithdrawalsService = useCallback(
    ({
      amount,
      currency,
      paymentSystems,
      accountName,
      accountNumber,
      bankCode,
    }) => {
      try {
        return api
          .post(post.aPayCreateWithdrawal, {
            amount,
            currency,
            paymentSystems,
            accountName,
            accountNumber,
            bankCode,
          })
          .then((response) => {
            return response
          })
      } catch (_) {}
    },
    []
  )

  return useMemo(
    () => ({
      aPayGetPaymentSystemLimitationWithSystemLimitationWithdrawalPlayerWithdrawalsService,
      getPlayerWithdrawalsForPaymentSystem,
      aPayCreateWithdrawalPlayerWithdrawalsService,
      getPlayerWithdrawalAmountPlayerWithdrawalsService,
    }),
    [
      aPayGetPaymentSystemLimitationWithSystemLimitationWithdrawalPlayerWithdrawalsService,
      getPlayerWithdrawalsForPaymentSystem,
      aPayCreateWithdrawalPlayerWithdrawalsService,
      getPlayerWithdrawalAmountPlayerWithdrawalsService,
    ]
  )
}
