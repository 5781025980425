import React from "react"
import "./menuIcon.scss"
const MenuIcon = ({ isOpen, toggleMenu }) => {
  return (
    <div className={`menu-icon ${isOpen ? "open" : ""}`} onClick={toggleMenu}>
      <div className="bar1"></div>
      <div className="bar2"></div>
      <div className="bar3"></div>
    </div>
  )
}

export default MenuIcon
