import React from "react"
import "./payText.scss"
import Icon from "../../icons"

const PayText = ({ text1, text2, iconType, alt_text }) => {
  return (
    <div className="paytext-block">
      <div className="paytext-icon">
        <Icon type={iconType} alt={alt_text} />
      </div>
      <div>
        <p>{text1}</p>
        <p>{text2}</p>
      </div>
    </div>
  )
}

export default PayText
