import React from "react"
import "./main-game-player-cash-out-btn.scss"
import { useTranslation } from "react-i18next"

const MainGameCheckbox = ({ bet }) => {
  const { t } = useTranslation()
  return (
    <div className="actions-game-rates__your-rate">
      {t("Your Bet")}:{" "}
      <span>
        <span id="future_bet_span_1">{bet.toFixed(2)}</span>{" "}
        <span className="user_currency">INR</span>
      </span>
    </div>
  )
}

export default MainGameCheckbox
