import { useState, useCallback, useEffect } from "react"
import { PlayerDepositsService } from "../../../../shared/api/axios/requests/player"
import useHttpHook from "../../../../shared/lib/hooks/use-http/http.hook"

const useAPayGetPaymentSystemLimitationDeposit = (paymentSystem) => {
  const { aPayGetPaymentSystemLimitationDepositPlayerDepositsService } =
    PlayerDepositsService()
  const { request, loading } = useHttpHook()
  const [data, setData] = useState({})

  const fetchData = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await request(() => {
        return aPayGetPaymentSystemLimitationDepositPlayerDepositsService(
          paymentSystem
        )
      })

      setData(data)
    } catch (_) {
    } finally {
    }
  }, [
    request,
    aPayGetPaymentSystemLimitationDepositPlayerDepositsService,
    paymentSystem,
  ])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return { data, loading }
}

export default useAPayGetPaymentSystemLimitationDeposit
