import React from "react"
import "./main-game-big-button.scss"
import loader from "../../../../assets/main/loader.png"
import { useTranslation } from "react-i18next"

const MainGameLittleButton = ({
  onClick,
  disabled,
  isBetExist,
  isLoading,
  isBetPlaced,
}) => {
  const { t } = useTranslation()

  return (
    <button
      onClick={onClick}
      className="main-game-big-button"
      disabled={disabled || isBetPlaced}
    >
      <span
        className={
          !isBetExist && !isLoading && !isBetPlaced ? `text active` : `text`
        }
      >
        {t("Bet")}
      </span>
      <span
        className={
          isBetExist && !isLoading && !isBetPlaced ? `cancel active` : `cancel`
        }
      >
        {t("Cancel")}
      </span>
      <span
        className={
          isLoading && !isBetPlaced
            ? `but_bet_1_loader active`
            : `but_bet_1_loader`
        }
      >
        <img className="active" src={loader} alt="active" />
      </span>

      <div
        className={
          isBetPlaced ? `loading-to-bet-btn active` : `loading-to-bet-btn`
        }
      >
        <div className="img-center-wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="256px"
            height="64px"
            viewBox="0 0 128 32"
            xmlSpace="preserve"
          >
            <circle
              fill="#ffffff"
              cx={0}
              cy={0}
              r={11}
              transform="translate(16 16)"
            >
              <animateTransform
                attributeName="transform"
                type="scale"
                additive="sum"
                values="1;1.42;1;1;1;1;1;1;1;1"
                dur="450ms"
                repeatCount="indefinite"
              />
            </circle>
            <circle
              fill="#ffffff"
              cx={0}
              cy={0}
              r={11}
              transform="translate(64 16)"
            >
              <animateTransform
                attributeName="transform"
                type="scale"
                additive="sum"
                values="1;1;1;1;1.42;1;1;1;1;1"
                dur="450ms"
                repeatCount="indefinite"
              />
            </circle>
            <circle
              fill="#ffffff"
              cx={0}
              cy={0}
              r={11}
              transform="translate(112 16)"
            >
              <animateTransform
                attributeName="transform"
                type="scale"
                additive="sum"
                values="1;1;1;1;1;1;1;1.42;1;1"
                dur="450ms"
                repeatCount="indefinite"
              />
            </circle>
          </svg>
        </div>

        <div className="text">{t("Waiting for launch")}</div>
      </div>
    </button>
  )
}

export default MainGameLittleButton
