import { useState, useCallback, useEffect } from "react"
import { PlayerWithdrawalsService } from "../../../../shared/api/axios/requests/player"
import useHttpHook from "../../../../shared/lib/hooks/use-http/http.hook"

const useGetWithdrawalAmount = () => {
  const { getPlayerWithdrawalAmountPlayerWithdrawalsService } =
    PlayerWithdrawalsService()
  const { request, loading } = useHttpHook()
  const [amount, setAmount] = useState(0)

  const fetchData = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await request(() => {
        return getPlayerWithdrawalAmountPlayerWithdrawalsService()
      })

      setAmount(data.amount)
    } catch (_) {
    } finally {
    }
  }, [request, getPlayerWithdrawalAmountPlayerWithdrawalsService])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return { amount, loading }
}

export default useGetWithdrawalAmount
