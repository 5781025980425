import React from "react"
import "./invalidPage.scss"
import { useTranslation } from "react-i18next"

const InvalidLinkPage = () => {
  const { t } = useTranslation()

  return (
    <div className="invalid-link-wrapper">
      <div className="text-wrapper">
        <p>{t("You have followed an outdated link")}.</p>
        <p>
          {t(
            "To enter the game, go to the bot, and again click the OPEN IN BROWSER button and get a new login link"
          )}
        </p>
      </div>
    </div>
  )
}

export default InvalidLinkPage
