import React from "react"
import "./main-game-player-list-btn.scss"
import returnIcon from "../../../../assets/main/return-icon.webp"

const MainGameCheckbox = ({ active, onClick }) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={"game-screen-players-btn" + (active ? " active-list" : "")}
    >
      <span>Minimize</span>
      <img
        src={returnIcon}
        alt="returnIcon"
        className={active ? "rotate-left" : ""}
      />
    </button>
  )
}

export default MainGameCheckbox
