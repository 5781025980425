import { useEffect } from "react"
import socket from "../../../api/socket.io/socket"
import useSocketEventHandlers from "./socket-event-handlers.hook"

const useSocketEventListener = () => {
  const handlers = useSocketEventHandlers()

  useEffect(() => {
    handlers.forEach(({ event, handler }) => {
      socket.on(event, handler)
    })

    socket.connect()

    return () => {
      handlers.forEach(({ event, handler }) => {
        socket.off(event, handler)
      })
      socket.disconnect()
    }
  }, [handlers])
}

export default useSocketEventListener
