import { useState } from "react"
import { PlayerGameService } from "../../../../shared/api/axios/requests/player"
import useHttpHook from "../../../../shared/lib/hooks/use-http/http.hook"
import { requestActionEnum } from "../../../utils"

const useGetGamingSessionData = () => {
  const { getGameInformationPlayerGameService } = PlayerGameService()
  const { request } = useHttpHook()
  const [isLoadingGetGamingSessionData, setIsLoadingGetGamingSessionData] =
    useState(true)
  const [gamingSessionInformation, setGamingSessionInformation] = useState({})

  const getGamingSessionInformationById = async (id) => {
    try {
      setIsLoadingGetGamingSessionData(true)
      const {
        data: { data },
      } = await request(() => {
        return getGameInformationPlayerGameService(
          requestActionEnum.GET_GAME_INFO,
          id
        )
      })

      setGamingSessionInformation(data)
    } catch (_) {
      setGamingSessionInformation({})
    } finally {
      setIsLoadingGetGamingSessionData(false)
    }
  }

  return {
    gamingSessionInformation,
    getGamingSessionInformationById,
    isLoadingGetGamingSessionData,
  }
}

export default useGetGamingSessionData
