import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import {
  MainGameBigButton,
  MainGameCheckbox,
  MainGameLittleButton,
  MainPagePlayerBetElement,
  MainPagePlayerCashOutBtn,
  MainPageQuantity,
} from "../../../shared/ui"
import "./betSection.scss"
import useBet from "../../../shared/lib/hooks/player/bet.hook"
import usePlayer from "../../../shared/lib/hooks/player/player.hook"
import useGamingSession from "../../../shared/lib/hooks/gaming-session/gaming-session.hook"
import useAutoCashout from "../../../shared/lib/hooks/player/autocashout.hook"
import {
  enableAutoCashout,
  disableAutoCashout,
  setAutoCashoutOption,
  setBetValue,
  setBetValueString,
  setStringAutoCashoutOption,
} from "../../../features/player/model/slice"
import {
  formatAutoCashoutOption,
  MAX_BET_VALUE,
  MIN_BET_VALUE,
} from "../../../shared/utils"
import { toggleClickSound } from "../../../app/actions/musicAction"
import { useTranslation } from "react-i18next"

const BetSection = ({ setBetButtonError, onInputFocus, onInputBlur }) => {
  const dispatch = useDispatch()
  const {
    currentFrontendMultiplier,
    isGamingSessionInitialized,
    isGamingSessionStarted,
    isGamingSessionFinished,
  } = useGamingSession()
  const {
    makeCashoutRequest,
    makeBet,
    makeBetOrMakeCancel,
    isMakeBetRequestSent,
    changeIsAutoBet,
  } = useBet()
  const {
    loading,
    balance,
    bet,
    betValue,
    betValueString,
    isAutoBet,
    isAutoCashoutEnabled,
    stringAutoCashoutOption,
    player,
    betError,
  } = usePlayer()
  useAutoCashout(isMakeBetRequestSent, makeCashoutRequest)

  const { t } = useTranslation()

  useEffect(() => {
    const preventScroll = (e) => {
      e.preventDefault()
    }

    const handleFocus = () => {
      // При фокусе на input запрещаем скроллинг
      document.addEventListener("touchmove", preventScroll, { passive: false })
    }

    const handleBlur = () => {
      // При потере фокуса разрешаем скроллинг
      document.removeEventListener("touchmove", preventScroll)
    }

    // Добавляем обработчики на input
    const input = document.querySelector("input")
    if (input) {
      input.addEventListener("focus", handleFocus)
      input.addEventListener("blur", handleBlur)
    }

    return () => {
      // Чистим обработчики при размонтировании компонента
      if (input) {
        input.removeEventListener("focus", handleFocus)
        input.removeEventListener("blur", handleBlur)
      }
    }
  }, [])

  const handleToggleClickSound = () => {
    dispatch(toggleClickSound())
  }

  useEffect(() => {
    if (stringAutoCashoutOption < 1) {
      dispatch(setStringAutoCashoutOption(1))
    }
    if (stringAutoCashoutOption > 100) {
      dispatch(setStringAutoCashoutOption(100))
    }
  }, [stringAutoCashoutOption, dispatch])

  useEffect(() => {
    if (isAutoBet && isGamingSessionInitialized && betValue) {
      makeBet(betValue)
    }
  }, [isAutoBet, isGamingSessionInitialized, betValue])

  const handleChangeAutoCashoutOption = (event) => {
    let value = event.target.value

    if (value === "") {
      dispatch(setStringAutoCashoutOption("1"))
      return
    }

    if (value.includes(",")) {
      value = value.replace(",", ".")
    }

    const allowedChars = /^[\d.]+$/
    if (!allowedChars.test(value)) {
      return
    }

    const regex = /^(100|[1-9]?\d(.\d{0,2})?)$/
    if (regex.test(value)) {
      dispatch(setStringAutoCashoutOption(value))
    }
  }

  useEffect(() => {
    dispatch(
      setAutoCashoutOption({
        autoCashoutOption: parseFloat(stringAutoCashoutOption),
      })
    )
  }, [stringAutoCashoutOption, dispatch])

  useEffect(() => {
    dispatch(setBetValue(parseFloat(betValueString)))
  }, [betValueString, dispatch])

  const handleChangeBet = (event) => {
    let value = event.target.value

    if (value === "") {
      dispatch(setBetValueString(""))
      return
    }
    if (balance < MIN_BET_VALUE) {
      dispatch(setBetValueString(MIN_BET_VALUE.toString()))
      return
    } else if (
      Number(value) > MAX_BET_VALUE ||
      (balance < MAX_BET_VALUE && Number(value) > balance)
    ) {
      dispatch(
        setBetValueString(
          balance > MAX_BET_VALUE
            ? MAX_BET_VALUE.toString()
            : balance.toString()
        )
      )
      return
    }

    if (value.includes(",")) {
      value = value.replace(",", ".")
    }

    const allowedChars = /^[\d.]+$/
    if (!allowedChars.test(value)) {
      return
    }

    const regex = /^\d*(\.\d{0,2})?$/
    if (regex.test(value)) {
      dispatch(setBetValueString(value))
    }
  }

  const multiplierValueOnClick = (value) => {
    let result = parseFloat((Number(betValueString) + Number(value)).toFixed(2))

    if (balance < MIN_BET_VALUE) {
      result = MIN_BET_VALUE
    } else if (result > balance || result > MAX_BET_VALUE) {
      result = balance > MAX_BET_VALUE ? MAX_BET_VALUE : balance
    }
    handleToggleClickSound()
    dispatch(setBetValueString(result.toString()))
  }

  const incrementAutoCashoutOption = () => {
    dispatch(
      setStringAutoCashoutOption(
        formatAutoCashoutOption(parseFloat(stringAutoCashoutOption) + 0.1)
      )
    )
    handleToggleClickSound()
  }
  const decrementAutoCashoutOption = () => {
    dispatch(
      setStringAutoCashoutOption(
        formatAutoCashoutOption(parseFloat(stringAutoCashoutOption) - 0.1)
      )
    )
    handleToggleClickSound()
  }

  const handleBetError = (message) => {
    const id = Date.now();
    const newError = { id, message }

    setBetButtonError((prevErrors) => [...prevErrors, newError])

    setTimeout(() => {
      setBetButtonError((prevErrors) =>
        prevErrors.filter((error) => error.id !== id)
      )
    }, 2000)
  }

  const [pingIssue, setPingIssue] = useState(false);

  useEffect(() => {
    if (bet && loading && isGamingSessionStarted) {
      setPingIssue(true);
    }
  
    if (isGamingSessionFinished) {
      setPingIssue(false);
    }
  }, [bet, loading, isGamingSessionStarted, isGamingSessionFinished]);

  return (
    <div className="bet-wrapper">
      {(isGamingSessionStarted || isGamingSessionFinished) &&
        isMakeBetRequestSent && !pingIssue && (
          <MainPagePlayerCashOutBtn
            onClick={() => {
              if (player.id) {
                makeCashoutRequest()
                handleToggleClickSound()
              } else {
                makeCashoutRequest()
                handleToggleClickSound()
              }
            }}
            disabled={loading}
            multiplier={currentFrontendMultiplier}
            bet={bet}
          />
        )}

      <div className="upper-flex">
        <div className="left-block">
          {(!isMakeBetRequestSent || isGamingSessionInitialized || !bet  || pingIssue) &&
            (!bet ? (
              <div className="first-section">
                <input
                  className="bet-input input"
                  // type="number"
                  // pattern="\d*"
                  onFocus={onInputFocus}
                  onBlur={onInputBlur}
                  type="text"
                  onKeyUp={(e) => {
                    e.target.value = e.target.value.replace(/[^\d,.]/g, "")
                  }}
                  inputMode="decimal"
                  value={betValueString}
                  onChange={handleChangeBet}
                />
                <MainGameLittleButton
                  onClick={() => {
                    dispatch(
                      setBetValueString(
                        balance < MIN_BET_VALUE
                          ? MIN_BET_VALUE
                          : balance > MAX_BET_VALUE
                            ? MAX_BET_VALUE.toString()
                            : balance.toString()
                      )
                    )
                    handleToggleClickSound()
                  }}
                  text={t("MAX")}
                />
                <MainGameLittleButton
                  onClick={() => multiplierValueOnClick(50)}
                  text={"+50"}
                />
                <MainGameLittleButton
                  onClick={() => multiplierValueOnClick(100)}
                  text={"+100"}
                />
                <MainGameLittleButton
                  onClick={() => multiplierValueOnClick(200)}
                  text={"+200"}
                />
                <MainGameLittleButton
                  onClick={() => multiplierValueOnClick(500)}
                  text={"+500"}
                />
                <MainGameLittleButton
                  onClick={() => multiplierValueOnClick(1000)}
                  text={"+1000"}
                />
              </div>
            ) : (
              <div className="first-section-solid">
                <MainPagePlayerBetElement bet={bet} />
              </div>
            ))}
          <div className="second-section">
            <MainGameCheckbox
              isActive={isAutoCashoutEnabled}
              onChange={() => {
                if (isAutoCashoutEnabled) {
                  dispatch(disableAutoCashout())
                  handleToggleClickSound()
                } else {
                  dispatch(enableAutoCashout())
                  handleToggleClickSound()
                }
              }}
              text={t("Auto cashout")}
            />
            <MainPageQuantity
              handleChange={handleChangeAutoCashoutOption}
              omClickDecrement={decrementAutoCashoutOption}
              omClickIncrement={incrementAutoCashoutOption}
              value={stringAutoCashoutOption}
            />
          </div>
        </div>

        <div className="right-block">
          {(!isMakeBetRequestSent || isGamingSessionInitialized || !bet || pingIssue ) && (
            <MainGameBigButton
              onClick={() => {
                if (Number.isNaN(betValue)) {
                  handleBetError("Minimum bet is 1 INR")
                  return
                }
                if (betValue < MIN_BET_VALUE) {
                  handleBetError("Minimum bet is 1 INR")
                  return
                }
                makeBetOrMakeCancel(betValue)
                handleToggleClickSound()
              }}
              isBetExist={bet}
              disabled={loading}
              isLoading={loading}
              isBetPlaced={bet && isGamingSessionInitialized}
            />
          )}

          <MainGameCheckbox
            isActive={isAutoBet}
            onChange={() => {
              changeIsAutoBet()
              handleToggleClickSound()
            }}
            text={t("Auto bet")}
          />
        </div>
      </div>
    </div>
  )
}

export default BetSection
