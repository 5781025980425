import api from "../../../../api"
import { endpoints } from "../../../../endpoints"
import { TokenService } from "../../../../../../services"

const { post, get } = endpoints.general.auth

const logoutStoreAuthService = () => {
  try {
    return api.post(post.logout, {}, { withCredentials: true }).then(() => {
      TokenService.removeLocalAccessToken()
    })
  } catch (_) {}
}

const getMeStoreAuthService = () => {
  try {
    return api.get(get.getMe).then((response) => {
      return response
    })
  } catch (_) {}
}

export const StoreAuthService = {
  logoutStoreAuthService,
  getMeStoreAuthService,
}
