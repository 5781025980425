import { createAsyncThunk } from "@reduxjs/toolkit"
import { TokenService } from "../../../shared/services"
import { StoreAuthService } from "../../../shared/api/axios/requests/general"
import {
  StoreGameService,
  StorePlayersService,
} from "../../../shared/api/axios/requests/player"
import { requestActionEnum } from "../../../shared/utils"
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const IS_PROXY_CONNECTION = process.env.REACT_APP_IS_PROXY_CONNECTION === "true"

export const getMyData = createAsyncThunk(
  !IS_PROXY_CONNECTION
    ? `${BACKEND_URL}/api/auth/my-data`
    : `/api/auth/my-data`,
  async ({ isDemo }, { rejectWithValue }) => {
    try {
      if (TokenService.getLocalAccessToken() && !isDemo) {
        const [player, balance, photo] = await Promise.all([
          StoreAuthService.getMeStoreAuthService(),
          StoreGameService.getMyActualBalanceStoreGameService(
            requestActionEnum.GET_ACTUAL_BALANCE
          ),
          StorePlayersService.getMyPhotoStorePlayersService(),
        ])

        return {
          player: player.data.data,
          balance: balance.data.data.balance,
          photo:
            photo && photo.data && photo.data.data && photo.data.data.photo
              ? photo.data.data.photo
              : null,
        }
      } else {
        const balance =
          await StoreGameService.getMyDemoActualBalanceStoreGameService(
            requestActionEnum.GET_DEMO_ACTUAL_BALANCE
          )

        return {
          balance: balance.data.data.balance,
        }
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const logout = createAsyncThunk(
  !IS_PROXY_CONNECTION ? `${BACKEND_URL}/api/auth/logout` : `/api/auth/logout`,
  async (_, { rejectWithValue }) => {
    try {
      await StoreAuthService.logoutStoreAuthService()
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const makeBet = createAsyncThunk(
  !IS_PROXY_CONNECTION
    ? `${BACKEND_URL}/api/game/make-bet`
    : `/api/game/make-bet`,
  async ({ action, bet, butNumb }, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await StoreGameService.makeBetPlayerGameService({
        action,
        bet,
        butNumb,
      })
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const makeCashout = createAsyncThunk(
  !IS_PROXY_CONNECTION
    ? `${BACKEND_URL}/api/game/make-cashout`
    : `/api/game/make-cashout`,
  async ({ action, autoCashoutOption, butNumb }, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await StoreGameService.makeCashoutPlayerGameService({
        action,
        autoCashoutOption,
        butNumb,
      })
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)
