import { useCallback, useMemo } from "react"
import api from "../../../api"
import { createQueryString } from "../../../../../utils"
import { endpoints } from "../../../endpoints"

const { get } = endpoints.player.transactions

export const PlayerTransactionsService = () => {
  const getMyTransactionHistoryPlayerTransactionsService = useCallback(
    ({ page, perPage }) => {
      try {
        return api
          .get(
            `${get.getMyTransactionHistory}?${createQueryString({ page, perPage })}`
          )
          .then((response) => {
            return response
          })
      } catch (_) {}
    },
    []
  )

  return useMemo(
    () => ({
      getMyTransactionHistoryPlayerTransactionsService,
    }),
    [getMyTransactionHistoryPlayerTransactionsService]
  )
}
