import React from "react"
import { AccountTitle, TransactionInfo } from "../../../widgets"
import "../../styleMain.scss"
import { useTranslation } from "react-i18next"

const TransactionPage = () => {
  const {t} = useTranslation()
  return (
    <div className="account-wrapper-background">
      <div className="account-wrapper">
        <div className="main-wrapper">
          <AccountTitle text={t("Transaction history")} />
          <TransactionInfo />
        </div>
      </div>
    </div>
  )
}

export default TransactionPage
