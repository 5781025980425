import React from "react"
import "./main-game-player-cash-out-btn.scss"
import { useTranslation } from "react-i18next"

const MainGameCheckbox = ({ onClick, disabled, multiplier, bet }) => {
  const { t } = useTranslation()
  return (
    <button
      type="button"
      id="cashout_1"
      className="actions-game__top actions-game-cashout animate"
      onClick={onClick}
      disabled={disabled}
    >
      <div className="actions-game-cashout__info">
        <div className="actions-game-cashout__info-coef">
          X<span className="now_x">{Math.floor(multiplier * 100) / 100}</span>
        </div>
        <div className="actions-game-cashout__info-sum">
          <span id="my_win_1">
            {(Math.floor(bet * multiplier * 100) / 100).toFixed(2)}
          </span>{" "}
          <span className="user_currency">INR</span>{" "}
        </div>
      </div>
      <div className="actions-game-cashout__text">{t("Cashout")}</div>
    </button>
  )
}

export default MainGameCheckbox
