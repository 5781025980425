import { useState } from "react"
import { useDispatch } from "react-redux"
import { PlayerWithdrawalsService } from "../../../../shared/api/axios/requests/player"
import useHttpHook from "../../../../shared/lib/hooks/use-http/http.hook"
import usePlayer from "../player/player.hook"
import { currencyEnum } from "../../../utils"
import { setNewBalance } from "../../../../features/player/model/slice"

const useAPayCreateWithdrawal = () => {
  const dispatch = useDispatch()
  const { balance } = usePlayer()
  const { aPayCreateWithdrawalPlayerWithdrawalsService } =
    PlayerWithdrawalsService()
  const { request, loading } = useHttpHook()
  const [error, setError] = useState(undefined)

  const createWithdrawal = async (
    amount,
    paymentSystem,
    accountName,
    accountNumber,
    bankCode
  ) => {
    try {
      const data = await request(() => {
        return aPayCreateWithdrawalPlayerWithdrawalsService({
          amount,
          currency: currencyEnum.INR,
          paymentSystems: [paymentSystem],
          accountName,
          accountNumber,
          bankCode,
        })
      })

      if (data.error && data.message && data.statusCode) {
        setError(data.message)

        setTimeout(() => {
          setError(undefined)
        }, 5000)
      } else if (data.statusCode === 429) {
        setError("Too Many Requests")
        setTimeout(() => {
          setError(undefined)
        }, 5000)
      }

      if (
        data &&
        data.data &&
        data.data.data &&
        data.data.data.amountBeforeFee
      ) {
        dispatch(setNewBalance(balance - data.data.data.amountBeforeFee))
      }
    } catch (_) {}
  }

  return {
    createWithdrawal,
    error,
    loading,
  }
}

export default useAPayCreateWithdrawal
