import React from "react"
import "./profileInfo.scss"
import { NavigationButton, RefillBalanceButton } from "../../../shared/ui"
import addBalance from "../../../shared/assets/addToBalance.png"
import OrderPay from "../../../shared/assets/orderPay.png"
import usePlayer from "../../../shared/lib/hooks/player/player.hook"
import useGetWithdrawalAmount from "../../../shared/lib/hooks/withdrawals/get-withdrawal-amount.hook"
import { formatDateToDDMMYYYYString } from "../../../shared/utils/functions/format-date-to-dd-mm-yyyy-string"
import { formatDateToLocalDate } from "../../../shared/utils/functions/format-date-to-local-date"
import { useTranslation } from "react-i18next"
import { Icon } from "../../../shared/ui"
import { DEFAULT_AVATAR_CONST } from "../../../shared/utils/consts/player.const"

const ProfileInfo = () => {
  const { player, balance, photo } = usePlayer()
  const { amount, loading } = useGetWithdrawalAmount()
  const { t } = useTranslation()

  return (
    <div className="account-profile">
      <div className="account-info-wrapper">
        <div className="account-image-wrapper">
          {photo === DEFAULT_AVATAR_CONST ? (
            <Icon
              type="profile"
              width={150}
              height={150}
              alt="userAvatar-icon"
            />
          ) : (
            <img
              className="avatar-img"
              src={photo}
              alt="User Avatar"
              crossOrigin="anonymous"
            />
          )}
        </div>
        <div className="account-information">
          <p>
            {player.lastName.length > 0
              ? player.firstName + " " + player.lastName
              : player.firstName}
          </p>
          <p>
            {t("Your ID")}: {player.id}
          </p>
          <p>
            {t("Posted by")}:{" "}
            {formatDateToDDMMYYYYString(
              formatDateToLocalDate(new Date(player.createdAt))
            )}
          </p>
        </div>
      </div>
      <div className="account-information-balance">
        <p>{t("Your Balance")}:</p>
        <p className="balance-count">{balance.toFixed(2)} INR</p>
        <NavigationButton
          link="/transaction"
          text={t("Transaction history")}
          color="#f29a0b"
        />
      </div>
      {loading ? (
        <div className="account-information-balance">
          <p>{t("Paid out")}:</p>
          <p className="balance-count">&nbsp;</p>{" "}
        </div>
      ) : (
        <div className="account-information-balance">
          <p>{t("Paid out")}:</p>
          <p className="balance-count">{amount}</p>
        </div>
      )}

      <div className="account-information-button">
        <RefillBalanceButton
          link="/refill"
          iconType="refillIcon"
          backgroundImage={addBalance}
          text={t("Refill Balance")}
          alt_text="refill-icon"
        />
        <RefillBalanceButton
          link="/payment"
          iconType="orderIcon"
          backgroundImage={OrderPay}
          text={t("Order a payment")}
          alt_text="order-icon"
        />
      </div>
    </div>
  )
}

export default ProfileInfo
