import React, { useState, useEffect } from "react"
import "./main-game-center.scss"
import { useTranslation } from "react-i18next"

const MainGameToast = ({ active }) => {
  const [progress, setProgress] = useState(0)
  const { t } = useTranslation()

  useEffect(() => {
    const duration = 4500
    const interval = 100
    const increment = 100 / (duration / interval)

    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(timer)
          return 100
        }
        return prevProgress + increment
      })
    }, interval)

    return () => clearInterval(timer)
  }, [])

  return (
    <div className={"game__modal" + (active ? " active" : "")} id="modal">
      <div className="game__title" id="text">
        {t("Waiting for launch")}
      </div>
      <div className="game__progressbar" id="progressbar">
        <div
          className="game__progressbar-fill"
          style={{ width: `${progress}%` }}
          id="progressbar-fill"
        ></div>
      </div>
    </div>
  )
}

export default MainGameToast
