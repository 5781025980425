export const TOGGLE_MUSIC = "TOGGLE_MUSIC"
export const TOGGLE__BALL_SOUND = "TOGGLE__BALL_SOUND"
export const SET_SOUND_FILE = "SET_SOUND_FILE"
export const TOGGLE__CLICK_SOUND = "TOGGLE__CLICK_SOUND"

export const toggleMusic = () => ({
  type: TOGGLE_MUSIC,
})

export const setSoundFile = (soundFile) => ({
  type: SET_SOUND_FILE,
  payload: soundFile,
})

export const toggleBallSound = () => ({
  type: TOGGLE__BALL_SOUND,
})

export const toggleClickSound = () => ({
  type: TOGGLE__CLICK_SOUND,
})
