import axios from "axios"
import { endpoints } from "./endpoints"
import { TokenService } from "../../services"

const { post } = endpoints.general.auth
let isRefreshing = false
let refreshQueue = []

function onRefreshed(accessToken) {
  refreshQueue.forEach((callback) => callback(accessToken))
  refreshQueue = []
}

const instance = axios.create({
  baseURL: "/",
  headers: {
    "Content-Type": "application/json",
  },
  credentials: "include",
})

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken()

    if (token) {
      config.headers["Authorization"] = "Bearer " + token
    }

    return config
  },
  (err) => {}
)

instance.interceptors.response.use(
  (res) => {
    return res
  },
  async (err) => {
    const originalConfig = err.config
    if (
      originalConfig.url !== post.playerAuth &&
      originalConfig.url !== post.refreshTokenAccess &&
      originalConfig.url !== post.logout &&
      err.response
    ) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true

        if (!isRefreshing) {
          isRefreshing = true

          try {
            const rs = await instance.post(
              post.refreshTokenAccess,
              {},
              { withCredentials: true }
            )

            const { accessToken } = rs.data
            TokenService.updateLocalAccessToken(accessToken)
            onRefreshed(accessToken)
            originalConfig.headers["Authorization"] = "Bearer " + accessToken
            return instance(originalConfig)
          } catch (_) {
            await instance.post(post.logout).then(() => {
              TokenService.removeLocalAccessToken()
              window.location.reload()
            })
          } finally {
            isRefreshing = false
          }
        } else {
          return new Promise((resolve) => {
            refreshQueue.push((accessToken) => {
              originalConfig.headers["Authorization"] = "Bearer " + accessToken
              resolve(instance(originalConfig))
            })
          })
        }
      }
    }

    // return err.response.data;
    // testing
    if (err?.response?.data) {
      return err.response.data
    } else {
      return { statusCode: err.code, message: err.message }
    }
  }
)

export default instance
