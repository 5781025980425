import React from "react"
import "./main-game-toast.scss"

const MainGameToast = ({ itemKey, toastType, text, header }) => {
  return (
    <div className={"toast " + toastType + " toast_show"} key={itemKey}>
      <div className="toast__header">{header} </div>
      <div className="toast__body">{text}</div>
      <button className="toast__close" type="button"></button>
    </div>
  )
}

export default MainGameToast
