import React from "react"
import "./inputPayment.scss"

const InputPayment = ({
  value,
  placeholder,
  text,
  isError,
  classAppInput,
  handleInput,
  maxLength,
  minLength,
  error,
  isDisabled,
  onChange,
}) => {
  return (
    <div className="default-input">
      <div>
        <p className="default-input-text">{text}</p>
        <input
          className={`default-input-item ${classAppInput} ${isError ? "error" : ""}`}
          placeholder={placeholder}
          type="text"
          inputMode="numeric"
          maxLength={maxLength}
          minLength={minLength}
          onInput={handleInput}
          value={value}
          disabled={isDisabled}
          onChange={onChange}
        ></input>
      </div>
      {error && <p className="error-message">{error}</p>}
    </div>
  )
}

export default InputPayment
