import React, { useEffect } from "react"
import "./App.scss"
import { BrowserRouter } from "react-router-dom"
import { useRoutes } from "./routes/general.routes"
import "./style/normalize.css"
import "./style/reset.css"
import useInitialAuthorization from "../shared/lib/hooks/auth/initial-auth.hook"
import { useTranslation } from "react-i18next"

function App() {
  const { t } = useTranslation()
  const {
    playerData,
    isPlayerDataReceivedOrCleared,
    isLinkInvalid,
    isLoadingPlayerAuthorization,
  } = useInitialAuthorization()
  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready()
      window.Telegram.WebApp.expand()
    }
  }, [])
  

  const routes = useRoutes(playerData, isPlayerDataReceivedOrCleared)
  if (isLoadingPlayerAuthorization) {
    return (
      <div className="game-wrapper-background">
        <div className="game-wrapper"></div>
      </div>
    )
  }

  if (isLinkInvalid) {
    return (
      <div className="game-wrapper-background">
        <div className="game-wrapper">
          <p>{t("You have followed an outdated link")}.</p>
          <p>
            {t(
              "To enter the game, go to the bot, and again click the OPEN IN BROWSER button and get a new login link"
            )}
            .
          </p>
        </div>
      </div>
    )
  }

  return !isPlayerDataReceivedOrCleared ? (
    <div className="App"></div>
  ) : (
    <div className="App">
      <BrowserRouter>
        <div className="Main-Wrapper">{routes}</div>
      </BrowserRouter>
    </div>
  )
}

export default App
