import React from "react"
import { Link } from "react-router-dom"
import "./pay-orderButton.scss"

const PayOrderButton = ({ text, link, onClick, isDisabled }) => {
  return (
    <Link to={link} className="pay-order-block" rel="noopener noreferrer">
      <button
        className="pay-order-button"
        onClick={isDisabled ? null : onClick}
        disabled={isDisabled}
      >
        <span>{text}</span>
      </button>
    </Link>
  )
}

export default PayOrderButton
