import { useState, useCallback, useEffect } from "react"
import { PlayerWithdrawalsService } from "../../../api/axios/requests/player"
import useHttpHook from "../use-http/http.hook"

const useAPayGetPaymentSystemLimitationDepositWithdrawal = (paymentSystem) => {
  const {
    aPayGetPaymentSystemLimitationWithSystemLimitationWithdrawalPlayerWithdrawalsService,
  } = PlayerWithdrawalsService()
  const { request, loading } = useHttpHook()
  const [data, setData] = useState({})

  const fetchData = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await request(() => {
        return aPayGetPaymentSystemLimitationWithSystemLimitationWithdrawalPlayerWithdrawalsService(
          paymentSystem
        )
      })
      setData(data)
    } catch (_) {}
  }, [
    paymentSystem,
    request,
    aPayGetPaymentSystemLimitationWithSystemLimitationWithdrawalPlayerWithdrawalsService,
  ])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return { data, loading }
}

export default useAPayGetPaymentSystemLimitationDepositWithdrawal
