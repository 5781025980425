import React, { useEffect, useState } from "react"
import "./main-game-player-list-item.scss"
import { PlayerPlayersService } from "../../../../api/axios/requests/player"

const PlayerListItem = ({ multiplier, active, name, sum, id }) => {
  const [img, setImg] = useState(null)
  const { getPhotoByIdPlayerPlayersService } = PlayerPlayersService()

  useEffect(() => {
    const fetchPlayerPhoto = async () => {
      if (id) {
        try {
         const { data } = await getPhotoByIdPlayerPlayersService(id)
          if (data && data.type !== "application/json") {
            setImg(URL.createObjectURL(data))
          }
        } catch (error) {

        }
      }
    }

    fetchPlayerPhoto()
  }, [id, getPhotoByIdPlayerPlayersService])
  return (
    <li
      className={
        "game-player" +
        (multiplier ? " game-player-winner" : "") +
        (active ? " active" : "")
      }
    >
      <div className="game-player-avatar">
      <div className="avatar-initials">
            <p>
              {name
                .split(" ")
                .map((word) => word.substring(0, 1))
                .join("")}

            </p>
          </div>
          {img && <img src={img} alt="" crossOrigin="anonymous" />}

      </div>
      <div className="game-player-body">
        <div className="game-player-name">{name}</div>
        <div className="game-player-info">
          <div className="game-player-rate-sum">
            {multiplier ? (multiplier * sum).toFixed(0) : sum}
            <span className="rupee-symbol"> ₹</span>
          </div>
          <div
            className={
              "game-player-coefficient" + (multiplier ? " active" : "")
            }
          >
            X{multiplier}
          </div>
        </div>
      </div>
    </li>
  )
}

export default PlayerListItem