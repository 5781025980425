import React from "react"
import "./disconect-popup.scss"

const ProfileInfo = ({text}) => {
  const handleReload = () => {
    window.location.reload() // Перезагрузка страницы
  }

  return (
    <div className="connection-lost-popup">
      <div className="popup-background"></div>
      <div className="popup-element">
        <div align="center">
          <svg
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              height: "100%",
              width: "100%",
              background: "none",
              shapeRendering: "auto",
            }}
            width="200px"
            height="200px"
          >
            <g
              className="ldl-scale"
              style={{
                transformOrigin: "50% 50%",
                transform: "rotate(0deg) scale(0.8, 0.8)",
              }}
            >
              <g className="ldl-ani">
                <g className="ldl-layer">
                  <g
                    className="ldl-ani"
                    style={{
                      transform: "scale(0.91)",
                      transformOrigin: "50px 50px",
                      animation:
                        "1.11111s linear -0.740741s infinite normal forwards running breath-59336935-3988-4677-a272-14a8a0ed17cb",
                    }}
                  >
                    <path
                      fill="#f4e6c8"
                      d="M50.5 25.1c-.2-.4-.8-.4-1 0L18.2 74.3c-.1.2-.1.5.1.7l.1.2h62.8c.1 0 .4 0 .5-.3.2-.3 0-.6 0-.6L50.5 25.1z"
                    />
                  </g>
                </g>
                <g className="ldl-layer">
                  <g
                    className="ldl-ani"
                    style={{
                      transform: "scale(0.91)",
                      transformOrigin: "50px 50px",
                      animation:
                        "1.11111s linear -0.925926s infinite normal forwards running breath-59336935-3988-4677-a272-14a8a0ed17cb",
                    }}
                  >
                    <path
                      fill="#c33837"
                      d="M57.2 20.6c-1.6-2.4-4.3-3.9-7.2-3.9-2.9 0-5.6 1.4-7.3 3.9L11.5 69.7c-1.8 2.7-2 6.1-.5 9 1.5 2.9 4.5 4.6 7.7 4.6h62.5c3.2 0 6.2-1.8 7.7-4.6 1.5-2.9 1.4-6.3-.4-9L57.2 20.6zm24.6 54.2c-.2.3-.4.3-.5.3H18.5l-.2-.1c-.2-.2-.2-.4-.1-.7l31.3-49.2c.3-.4.8-.4 1 0l31.3 49.1c0 .1.2.3 0 .6z"
                    />
                  </g>
                </g>
                <g className="ldl-layer">
                  <g
                    className="ldl-ani"
                    style={{
                      transform: "scale(0.91)",
                      transformOrigin: "50px 50px",
                      animation:
                        "1.11111s linear -1.11111s infinite normal forwards running breath-59336935-3988-4677-a272-14a8a0ed17cb",
                    }}
                  >
                    <path
                      d="M61.9 52L57 47.1l-7 7-7-7-4.9 4.9 7 7-7 7 4.9 4.9 7-7 7 7 4.9-4.9-7-7z"
                      fill="#e15c64"
                    />
                  </g>
                </g>
              </g>
            </g>
            <style>{`
      @keyframes breath-59336935-3988-4677-a272-14a8a0ed17cb {
        0% {
          animation-timing-function: cubic-bezier(0.9647,0.2413,-0.0705,0.7911);
          transform: scale(0.9099999999999999);
        }
        51% {
          animation-timing-function: cubic-bezier(0.9226,0.2631,-0.0308,0.7628);
          transform: scale(1.02994);
        }
        100% {
          transform: scale(0.9099999999999999);
        }
      }
    `}</style>
          </svg>
          <div className="first-text">{text}</div>
          <div className="second-text" onClick={handleReload}>
            Click to reload page
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileInfo
