import React from "react"
import "./profileFooter.scss"
import { useTranslation } from "react-i18next"

const ProfileFooter = () => {
  const { t } = useTranslation()
  return (
    <footer className="footer">
      <div className="footer-menu">
        <a target="_blank" href="https://t.me/cricket_jet" rel="noopener noreferrer">
          © {t("Cricket Jet")}
        </a>
      </div>
    </footer>
  )
}

export default ProfileFooter
