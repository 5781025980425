import React from "react"
import { Icon, NavigationButton } from "../../../shared/ui"
import { Link } from "react-router-dom"
import BurgerMenu from "../../sidebar/ui"
import "./profileHeader.scss"
import usePlayer from "../../../shared/lib/hooks/player/player.hook"
import { DEFAULT_AVATAR_CONST } from "../../../shared/utils/consts/player.const"
import { useTranslation } from "react-i18next"

const ProfileHeader = () => {
  const { t } = useTranslation()
  const { balance, photo } = usePlayer()

  return (
    <header className="header">
      <div className="header-menu">
        <BurgerMenu />
      </div>
      <div className="header-menu-balance">
        <div className="header-menu-balance-indent">
          <Icon
            type="wallet"
            width={18}
            height={18}
            color="#f29a0b"
            alt="Wallet"
          />
          <span className="header-menu-balance-count">
            {balance.toFixed(2)} INR
          </span>{" "}
        </div>
        <div className="header-menu-balance-indent">
          <NavigationButton
            iconType="refillTop"
            text={t("Refill")}
            link="/refill"
            alt_text="refill-icon"
            width={14}
            height={14}
            color="#22e8ff"
            textDecoration="underline"
          />
        </div>
      </div>
      <Link to="/profile" className="header-profile" rel="noopener noreferrer">
        {photo === DEFAULT_AVATAR_CONST ? (
          <Icon type="profile" width={40} height={40} alt="userAvatar-icon" />
        ) : (
          <img
            crossOrigin="anonymous"
            className="avatar-img"
            src={photo}
            alt="User Avatar"
            style={{
              width: 40,
              height: 40,
              display: "inline-block",
              fill: "inherit",
              borderRadius: "100px",
            }}
          />
        )}
      </Link>
    </header>
  )
}

export default ProfileHeader
