import React from "react"
import * as Icons from "../../lib/icons/icons"

const iconMapping = {
  profile: Icons.Profile,
  wallet: Icons.Wallet,
  coins: Icons.Coin,
  handshake: Icons.HandShake,
  lifering: Icons.LifeRing,
  logout: Icons.LogOut,
  settings: Icons.Settings,
  support: Icons.Support,
  withdrawal: Icons.Withdrawal,
  refillTop: Icons.RefillTop,
  language: Icons.Language,
  transaction: Icons.Transaction,
  speakerOn: Icons.SpeakerOn,
  speakerOff: Icons.SpeakerOff,
  info: Icons.Info,
  refillIcon: Icons.RefillIcon,
  orderIcon: Icons.OrderIcon,
  arrowHeader: Icons.ArrowHeader,
  leftArrow: Icons.LeftArrow,
  imps: Icons.IMPS,
  paytm: Icons.PayTM,
  phonepe: Icons.PhonePE,
  upi_fast_v: Icons.UpiFastV,
  upi_fast: Icons.UpiFast,
  upi_p2p: Icons.UpiP2p,
  upi_push: Icons.UpiPush,
}

const Icon = ({ type, width, height, color = "", alt = "icon", className, onClick }) => {
  const SvgIcon = iconMapping[type]

  if (!SvgIcon) {
    return null
  }

  const styles = {
    width: width,
    height: height,
    display: "inline-block",
    fill: color,
  }

  if (typeof SvgIcon === "string") {
    return (
      <img
        src={SvgIcon}
        alt={alt}
        style={styles}
        className={className}
        onClick={onClick}
        crossOrigin="anonymous"
      />
    )
  }

  return <SvgIcon aria-label={alt} style={styles} />
}

export default Icon
