import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import click from "../../shared/assets/audio/click.mp3"
import endGameBallSound from "../../shared/assets/audio/endGameBallSound.mp3"
import gameEndSound from "../../shared/assets/audio/gameEndSound.mp3"
import startHit from "../../shared/assets/audio/startHit.mp3"
import superGameEndSound from "../../shared/assets/audio/superGameEndSound.mp3"

const BackgroundMusic = () => {
  const isPlaying = useSelector((state) => state.sound.isPlaying)
  const soundFile = useSelector((state) => state.sound.soundFile)

  const getAudioFile = (soundFile) => {
    switch (soundFile) {
      case "click":
        return click
      case "endGameBallSound":
        return endGameBallSound
      case "gameEndSound":
        return gameEndSound
      case "startHit":
        return startHit
      case "superGameEndSound":
        return superGameEndSound
      default:
        return click
    }
  }

  const audio = React.useRef(null)

  useEffect(() => {
    const audioElement = new Audio(getAudioFile(soundFile))
    audio.current = audioElement
    audioElement.volume = 0.05
    audioElement.loop = false

    if (isPlaying) {
      audioElement.play()
    } else {
      audioElement.pause()
    }

    return () => {
      audioElement.pause()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soundFile])

  return null
}

export default BackgroundMusic
