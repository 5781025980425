import React, { useState } from "react"
import { useParams } from "react-router-dom"
import {
  BackButton,
  PayText,
  EnterAmount,
  PayOrderButton,
} from "../../../shared/ui"
import "./payContent.scss"
import useAPayGetPaymentSystemLimitationDeposit from "../../../shared/lib/hooks/deposits/a-pay-get-payment-system-limitation-deposit.hook"
import useAPayCreatePage from "../../../shared/lib/hooks/deposits/a-pay-create-page.hook"
import { useTranslation } from "react-i18next"

const PayContent = () => {
  const {t} = useTranslation()
  const paymentSystem = useParams().paymentSystem
  const [value, setValue] = useState("")
  const [error, setError] = useState("")

  const { data: dataLimitation, loading: loadingLimitation } =
    useAPayGetPaymentSystemLimitationDeposit(paymentSystem)

  const {
    createPage,
    error: errorCreatePage,
    loading: loadingCreatePage,
  } = useAPayCreatePage()

  if (loadingLimitation) {
    return <></>
  }

  const handleInput = (e) => {
    const newValue = e.target.value

    if (/^\d*$/.test(newValue)) {
      setValue(newValue)

      if (newValue && Number(newValue) < dataLimitation.minDeposit) {
        setError(`The value must be at least ${dataLimitation.minDeposit}`)
      } else if (newValue && Number(newValue) > dataLimitation.maxDeposit) {
        setError(`The value must not exceed ${dataLimitation.maxDeposit}`)
      } else {
        setError("")
      }
    }
  }
  const isButtonDisabled =
    !value ||
    Number(value) < dataLimitation.minDeposit ||
    Number(value) > dataLimitation.maxDeposit ||
    loadingCreatePage

  return (
    <div className="pay-section">
      <BackButton
        iconType="leftArrow"
        text={t("Go back")}
        link="/refill"
        alt_text="back-icon"
        width={26}
        height={26}
        color="#8689ac"
        onClick={(e) => {
          if (loadingCreatePage) {
            e.preventDefault()
          }
        }}
        style={{
          pointerEvents: loadingCreatePage ? "none" : "auto",
          opacity: loadingCreatePage ? 0.5 : 1,
        }}
      />
      {errorCreatePage && (
        <p className="pay-section-error-back-message">{errorCreatePage}</p>
      )}
      <PayText
        iconType={paymentSystem}
        text1={t("Funds are credited to the balance automatically.")}
        text2={t("Enter the amount in dollars that you want to top up to your balance.")}
        alt_text="payMethod"
      />
      <EnterAmount
        minDeposit={dataLimitation.minDeposit}
        maxDeposit={dataLimitation.maxDeposit}
        handleInput={handleInput}
        value={value}
        error={error}
        isDisabled={loadingCreatePage}
      />
      <PayOrderButton
        onClick={() => {
          if (!isButtonDisabled) {
            createPage(Number(value), paymentSystem)
          }
        }}
        text={t("Refill Balance")}
        isDisabled={isButtonDisabled}
      />
    </div>
  )
}

export default PayContent
