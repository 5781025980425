import React from "react"
import "./accountTitle.scss"

const AccountTitle = ({ text }) => {
  return (
    <div className="title-block">
      <h2>{text}</h2>
    </div>
  )
}

export default AccountTitle
