import { useEffect } from "react"
import usePlayer from "./player.hook"
import useGamingSession from "../gaming-session/gaming-session.hook"

const useAutoCashout = (isMakeBetRequestSent, makeCashoutRequest) => {
  const { isAutoCashoutEnabled, autoCashoutOption, bet } = usePlayer()
  const { currentFrontendMultiplier, isGamingSessionStarted } =
    useGamingSession()

  useEffect(() => {
    if (
      isAutoCashoutEnabled &&
      bet &&
      isGamingSessionStarted &&
      autoCashoutOption &&
      isMakeBetRequestSent
    ) {
      if (currentFrontendMultiplier >= autoCashoutOption) {
        makeCashoutRequest(autoCashoutOption)
      }
    }
  }, [
    currentFrontendMultiplier,
    autoCashoutOption,
    isAutoCashoutEnabled,
    makeCashoutRequest,
    bet,
    isGamingSessionStarted,
    isMakeBetRequestSent,
  ])
}

export default useAutoCashout
