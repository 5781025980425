import { useCallback, useMemo } from "react"
import api from "../../../api"
import { endpoints } from "../../../endpoints"

const { get } = endpoints.general.common

export const CommonService = () => {
  const pingCommonService = useCallback(() => {
    try {
      return api
        .get(get.ping, {
          withCredentials: true,
        })
        .then((response) => {
          return response
        })
    } catch (_) {}
  }, [])

  return useMemo(
    () => ({
      pingCommonService,
    }),
    [pingCommonService]
  )
}
