import React from "react"
import { Icon } from "../../../shared/ui"
import "./refillBalanceCard.scss"
import { Link } from "react-router-dom"

const RefillBalanceCard = ({ link, iconType, text, alt_text }) => {
  return (
    <div className="select-pay-grid">
      <Link to={link} className="select-pay-item" rel="noopener noreferrer">
        <div className="select-pay-img-wrapper">
          <Icon
            className="payments-collumn-img"
            type={iconType}
            alt={alt_text}
          />
        </div>
        <span className="payments-collumn-title">{text}</span>
      </Link>
    </div>
  )
}

export default RefillBalanceCard
